// Schedule a call button css start
.scheduleCall {
  position: fixed;
  bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  right: 50px;
  cursor: pointer;
  z-index: 1;
}
@media only screen and (max-width:768px){
  .scheduleCall{
    bottom: 15px;
    right: 15px;
  }
}

.scheduleCallButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  padding: 18px;
  border-radius: 50px;
  text-decoration: none;
  background: #50b47e;
  border-color: transparent;
  color: white;
}
.scheduleCall:hover .scheduleCallButton {
  padding-left: 25px;
  padding-right: 25px;
}
.scheduleCallbtnImg {
  width: 33px;
  height: 39px;
}
.schedulepara-btn {
  max-width: 0;
  padding-left: 0;
  opacity: 0;
  font-size: 0;
  transform: translateX(-10px);
  transition: max-width 0.4s ease, padding-left 0.4s ease, opacity 0.4s ease 0.4s, font-size 0.4s ease 0.2s, transform 0.4s ease;
  overflow: hidden;
  white-space: nowrap;
}

.scheduleCall:hover .schedulepara-btn {
  font-weight: 500;
  max-width: 200px;
  padding-left: 15px;
  font-size: 18px;
  opacity: 1;
  transform: translateX(0);
}
// Schedule a call button css end

/* Sidebar css start*/
.sidebar {
  position: fixed;
  top: 0;
  right: -435px; /* Hidden by default */
  width: 435px;
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: -7px 0px 11px -8px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: -7px 0px 11px -8px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: -7px 0px 11px -8px rgba(0, 0, 0, 0.52);
  transition: right 0.3s ease;
}
@media only screen and (max-width: 768px) {
  .sidebar {
    right: -100%;
    width: 100%;
  }
}
.agentForm {
  margin-top: 14%;
  text-align: center;
  width: 50%;
}

.sidebar.open {
  right: 0; /* Sidebar slides in when open */
  z-index: 99;
}
.sidebarHeading {
  font-size: 18px;
  font-weight: 600;
  @include mq("xl", "max") {
    font-size: 14px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 14px;
    // from 0px to 768px
  }
}
.sidebarCaption {
  font-size: 14px;
  @include mq("xl", "max") {
    font-size: 12px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 12px;
    // from 0px to 768px
  }
}
.scheduleHeaderImg {
  padding-right: 0px;
}
@media only screen and (max-width: 768px) {
  .scheduleHeaderImg {
    display: none;
  }
}
.advisorSchedule {
  padding-left: 30px;
}
.sidebarHeading {
  font-size: 18px;
}
.scheduleHeaderImg img {
  width: 100%;
}

.sidebarContent h2 {
  margin-top: 0;
}

.callForm {
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
}
.confirmbut {
  position: absolute;
  bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  z-index: 1;
  width: 86%;
  color: white;
  background: #e91431 0% 0% no-repeat padding-box;
  border: 1px solid #e91431;
  border-radius: 5px;
  opacity: 1;
  height: 50px;
}
.crossIconForm {
  background-color: transparent !important;
  position: absolute;
  right: 20px;
  font-size: 25px;
  border: none;
}
.padding-pa {
  padding-left: 20px;
  padding-right: 20px;
}
.btn-scheduleaCall {
  color: black !important;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  opacity: 1;
  font-size: 14px;
}
@media only screen and (max-width:768px){
  .btn-scheduleaCall{
    font-size: 11px;
  }
}
/* Active tab style */
.btn-scheduleaCall.active {
  background: #fff9f9 0% 0% no-repeat padding-box !important;
  border: 1px solid #e91431 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}
/* Sidebar css end*/

.main-header {
  height: 60px;
  background: white;
  box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.mh-logo {
  width: 104px !important;
  height: 34px !important;
  margin: 12px;
}

.fill-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This property will make the image cover the entire container */
}

.mh-nav {
}

.mh-n-list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 23px;
}

.mh-n-link {
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    color: $primary;
  }

  .mh-n-icon {
    font-size: 20px;
    line-height: 0;
    position: relative;
    display: flex;
    align-items: center;
  }
}

.mh-n-count {
  position: absolute;
  background: $primary;
  @include circle(16px);
  right: -6px;
  top: -4px;
  color: white;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mh-n-arrow {
  font-size: 10px;
  transition: all 0.1s ease-in-out;
}

.auth-btn {
  margin-left: 17px;
}

.mh-n-dropdown {
  .dropdown-menu {
    margin-top: -2px;
    border: none;
    border-top: 1px solid $primary;
    border-radius: 0 0 10px 10px;
    padding: 0;
    box-shadow: 0 3px 6px rgba(black, 0.1);

    &:before {
      content: url(../../../public/assets/img/dropdown-arrow.svg);
      width: 12px;
      height: 0;
      position: absolute;
      top: -13px;
      left: 36px;
    }
    &[data-popper-placement="bottom-end"] {
      &:before {
        left: auto;
        right: 60px;
      }
    }
  }

  .dropdown-toggle {
    height: 60px;
    &.show {
      color: $primary;

      .mh-n-arrow {
        transform: rotate(0.5turn);
      }
    }

    &:after {
      display: none;
    }
  }

  .dropdown-item {
    font-size: 14px;
    padding: 12px;
    min-width: 216px;
    border-radius: 0;

    &:not(:last-child) {
      border-bottom: 1px solid $gray-300;
    }

    .mh-n-icon {
      font-size: 20px;
      line-height: 1;
      @include square(30px);
      display: flex;
      align-items: center;
      justify-content: center;
      &.lg {
        font-size: 24px;
      }
      &.sm {
        font-size: 16px;
      }
    }
  }
}

.mh-profile {
  display: flex;
  align-items: center;
  gap: 8px;

  &.mh-profile-lg {
    gap: 20px;

    .mh-p-fig {
      @include circle(64px);
    }

    .mh-p-name {
      font-size: 20px;
    }

    .mh-p-logout {
      font-size: 16px;
    }
  }
}

.mh-p-fig {
  @include circle(40px);
  border: 1px solid $primary;
  overflow: hidden;
  margin: 0;

  img {
    @include square(100%);
    object-fit: cover;
  }
}

.mh-p-name {
  font-weight: 500;
  color: $gray-900;
}

.mh-p-logout {
  color: $primary;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
}

.mh-menu {
  align-self: center;
}

.mh-menu-btn {
  background: none;
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  font-size: 25px;
  line-height: 0;
}

.mh-responsive-hide {
  @include mq("lg", "max") {
    display: none;
  }
}

.mh-responsive-show {
  @include mq("lg", "min") {
    display: none;
  }
}

// Auth Modal

.auth-modal {
  .btn-close {
    margin-top: -24px;
  }

  .modal-header {
    background: none;
    border: none;
  }

  .modal-title {
    font-size: 20px;
    color: $gray-900;
    font-weight: 600;
    background: none;

    small {
      font-size: 12px;
      font-weight: normal;
      display: block;
    }
  }
}

// Cart Modal
.cart-modal-wrapper {
  max-width: 450px;

  @include mq("md", "max") {
    font-size: 14px;
  }

  .modal-title {
    .svg-icon {
      font-size: 20px;
    }
  }

  .modal-footer {
    display: block;
    padding: 0;
    box-shadow: 0 -3px 14px rgba(black, 0.07);
  }

  .modal-header {
    box-shadow: 0 3px 14px rgba(black, 0.07);
  }
}
.product-widget-baadmay{
  height: 100%;
  width: 1150px;
  position: fixed;
  right: 80px;
  top: 0px;

  @include mq("md", "max") {
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
  }

}
.customCart{
  position: absolute;
  bottom: 0px;
  @include mq("md", "max") {
    position: relative;
  }
}
.pww-custombtn{
  position: fixed;
  right: 10px;
  @include mq("md", "max") {
    position: static;
  }
}

.customFormBox{
  background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E91431;
    border-radius: 7px;
    opacity: 1;
    padding: 27px;
}
.baadmayCustomContent{
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: -0.42px;
  color: #515151;
  opacity: 1;
  border-top: 1px solid #AAAAAA;
  margin-bottom: 0px;
}
.customFormBox #baadmay{
  margin-top: -90px;
}
.cmw-item {
  padding-left: 20px;
  padding-right: 20px;

  &:not(:first-child) {
    padding-top: 24px;
  }

  &:not(:last-child) {
    border-bottom: 5px solid $gray-100;
    padding-bottom: 30px;
  }
}

.cmw-remove {
  font-size: 20px;
  line-height: 1;
  color: $gray-900;
  margin-left: auto;

  &:hover {
    color: $primary;
  }
}

.cmw-head {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.cmw-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cmw-fig {
  text-align: center;
  margin: 0;

  img {
    max-width: 100px;
    max-height: 60px;
  }

  figcaption {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }
}

.cmw-cost {
  font-size: 24px;
  font-weight: 600;
  color: $primary;
  line-height: 1;
}

.cmw-foot {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  margin: 0;

  &:last-child:not(:first-child) {
    border-top: 1px solid $gray-300;
  }
}

.cmw-empty {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  flex-direction: column;
  max-width: 220px;
  margin: auto;
  text-align: center;

  img {
    width: 100%;
  }
}

// Mobile Navbar

.mobile-navbar {
  width: 345px !important;

  .offcanvas-body {
    overflow-y: auto;
  }
}

.mn-logo {
  height: 50px;
}

.mn-nav {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.mn-n-item {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }

  padding: 20px 5px;
}

.mn-n-link {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: $gray-900;
  font-weight: 500;
}

.mn-n-arrow {
  font-size: 14px;
  color: $primary;
}

.mn-subnav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 15px;
}

.mn-s-link {
  display: flex;
  font-size: 16px;
  color: $gray-900;
  text-decoration: none;
}

.mn-foot {
  padding: 20px 16px;
  border-top: 5px solid $gray-100;
}

.mn-contact {
  border: 1px solid $gray-400;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 20px;
  height: 60px;
  cursor: pointer;
  background: none;
  text-align: left;
  padding: 0 15px;
  width: 100%;

  svg {
    font-size: 30px;
    color: $primary;
  }
}

// MegaMenu dropdown css starts
.productsDropdown{
  position: absolute;
  width: 100%;
  background-color: white;
  left: 0;
  top: 60px;
}
.megaMenu {
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  // border: 1px solid #ccc;
  box-shadow: -1px 0px 6px 2px rgba(199,199,199,0.75);
-webkit-box-shadow: -1px 0px 6px 2px rgba(199,199,199,0.75);
-moz-box-shadow: -1px 0px 6px 2px rgba(199,199,199,0.75);
  z-index: 10;
  padding: 50px 0px;
  box-sizing: border-box;
  cursor: auto;
}

.productsButton{
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
  color: #58595B;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.megaMenuh3{
  color: #E91431;
  font-size: 22px;
  font-weight: 600;
  color: #E91431;
}
.megaMenuh4{
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: #E91431;
  align-content: end;
}
.megaMenu ul li{
  list-style: none;
}
.imgCustomSize{
  width: 34px;
  height: 31px;
  margin-right: 22px;
}
.listingMegaMenu{
  padding-top: 10px;
  padding-left: 57px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.listingMegaMenu li{
  line-height: 2;
  font-size: 14px;
  color: #515151;
  font-weight: 500;
}
.healthCategoryImage{
  width: 83px;
  height: 20px;
  margin-left: 20px;
}
.MegaRightBox{
  background-color: #FFF3F5;
  border-radius: 8px;
  cursor: pointer;
}
.MegaRightBox h5{
  font-size: 16px
}
.MegaRightBox p{
  font-size: 14px;
  color: #515151;
}
.borderRightDD{
  border-right: 2px solid #0000001A;
}
.productImage {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.productImage.opened {
  transform: rotate(180deg); /* Points down when open */
}

.productImage.closed {
  transform: rotate(0deg); /* Points up when closed */
}
.listingCats{
  text-decoration: none !important;
  color: #515151 ;
  font-weight: 500;
  line-height: 2;
}
.mh-n-arrow {
  display: inline-block;
  transition: transform 0.3s ease; /* Smooth rotation */
}

.mh-n-arrow.open {
  transform: rotate(180deg); /* Rotate 180 degrees when open */
}

.mh-n-arrow.closed {
  transform: rotate(0deg); /* Default rotation */
}

// MegaMenu dropdown css end
