@use "sass:math";
// conver px to rem
@function rem($pixels, $context: 16) {
  @return math.div($pixels, $context) * 1rem;
}

// Media Query
@mixin mq($breakpoint, $type: min) {
  // Checking $breakpoint value in $grid-breakpoints object value
  @if map_has_key($grid-breakpoints, $breakpoint) {
    // getting $grid-breakpoints object value and asign width
    $width: map_get($grid-breakpoints, $breakpoint);
    // Decrease 1px from $grid-breakpoints value for max-width $type
    @if $type == max {
      $width: $width - 1px;
    }
    // Print Media query
    @media (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin mq-only($min-width, $max-width) {
  @if map_has_key($grid-breakpoints, $min-width) and map_has_key($grid-breakpoints, $max-width) {
    // define value to relevant$grid-breakpoints
    $min-width: map_get($grid-breakpoints, $min-width);
    $max-width: map_get($grid-breakpoints, $max-width) - 1px;

    @media (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  } @else {
    @error "Please Define correct media query breakpoint";
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin circle($size) {
  @include square($size);
  border-radius: 50%;
}

@mixin is-parent($parent-selector) {
  $current-sequences: &;
  $new-sequences: ();

  @each $sequence in $current-sequences {
    $current-selector: nth($sequence, -1);
    $prepended-selector: join($parent-selector, $current-selector);
    $new-sequence: set-nth($sequence, -1, $prepended-selector);
    $new-sequences: append($new-sequences, $new-sequence, comma);
  }

  @at-root #{$new-sequences} {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
