.breadcrumb {
  font-size: 12px;
  margin: 0;
}

.breadcrumb-item {
  color: $gray-500;

  &:before {
    color: $gray-500 !important;
    font-weight: normal !important;
  }

  a {
    color: $gray-500;
    text-decoration: none;
  }
  &.active {
    color: $gray-900;
    font-weight: 500;
  }
}
