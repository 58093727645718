.dashboard-tabs-block {
  flex-direction: column;
  gap: 10px;
  display: flex;

  @include mq("lg", "max") {
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $gray-300;
    margin-bottom: 30px;
  }

  .dtb-link {
    font-size: 14px;
    font-weight: 500;
    color: $gray-900;
    padding: 12px;
    min-height: 48px;
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    border-radius: 6px;
    word-break: normal;

    @include mq("lg", "max") {
      flex-direction: column;
      padding: 0 0 10px;
      border-radius: 0;
      gap: 10px;
      margin-bottom: -2px;
      text-align: center;
    }

    @include mq("md", "max") {
      font-size: 12px;
    }

    &.active {
      color: $primary;
      background: lighten($primary, 45);

      @include mq("lg", "max") {
        background: none;
        border-bottom: 3px solid $primary;
      }

      .svg-icon {
        color: $primary;
      }
    }
  }

  .svg-icon {
    font-size: 24px;
    color: $gray-600;
  }
}
