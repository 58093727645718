.fs-14px {
  font-size: 14px;
}

.fs-12px {
  font-size: 12px;
}

.fs-16px {
  font-size: 16px;
}

.fs-18px {
  font-size: 18px;
}

.fs-20px {
  font-size: 18px;
}

.fs-30px {
  font-size: 30px;
}

.c-pointer {
  cursor: pointer;
}

.fs-24px {
  font-size: 24px;
}

.fs-22px {
  font-size: 22px;
}

.t-rotate-180 {
  transform: rotate(0.5turn);
}

.t-all {
  transition: $transitionValue;
}

.primary-bg-subtle {
  background: rgba($primary, 0.03);
}

.container-mini {
  @include mq("lg", "min") {
    max-width: 960px;
  }
}

.hide-empty {
  &:empty {
    display: none;
  }
}
