.pagination {
  margin: 0;
  gap: 5px;
  line-height: 1.2;
  position: relative;
  z-index: 0;
}

.page-link {
  border: none;
  font-size: 14px;
  border-radius: 6px;
  color: $gray-900 !important;
  border: 1px solid transparent;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  padding: 5px;

  &:focus {
    background: none;
    border-color: transparent;
  }

  svg {
    font-size: 8px;
  }
}

.page-item {
  &:not(.disabled) {
    &:not(.active):hover {
      .page-link {
        border-color: transparent;
      }
    }

    &.active {
      .page-link {
        background: none;
        color: $gray-900;
        border-color: $gray-300;
      }
    }
  }

  &.disabled {
    .page-link {
      background: none;
      border-color: transparent;
      color: $gray-400 !important;
    }
  }
}
