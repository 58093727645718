.nextBtn{
  width: 100%;
}
.travel-journey-form{
  width: 25%;
  position: absolute;
  top: -10px;
  right: 6%;
  margin: 10% 2%;
  border: .9px solid #c9c9c9;
  background-color: white;
  padding: 25px 10px;
  dominant-baseline: d;

  @include mq("lg", "max") {
    position: static;
    width: 80%;
  }
}
.modal-body .travel-journey-form{
  width: 100% !important;
  margin: 0% 0% !important;
  position: static !important;
  padding: 17px 10px !important;
}
.groupCountriesDD{
  display: flex;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  padding: 14px 10px;
}
// .p-datepicker-group-container{
//   background-color: white;
//   width: 422px;
//   position: absolute;
//   right: -31px;
//   z-index: 999 !important;
// }
.p-datepicker{
  z-index: 99999 !important;
  background-color: #fff;
}

.dropdown-list{
  position: absolute;
  top: '100%';
  z-index: 1050;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  width: 100%;
}
.travel-takaful-plan-hero {
  background: url('../../../public//assets/img/newBannerTravel.png') no-repeat center bottom #808285;
  background-size: cover;
  min-height: 720px;

  @include mq("lg", "max") {
    background: $gray-200;
    min-height: 0;
  }
}
.borderCustomClass{
  border-bottom: 1px dotted #ccc;
}
.dropdown-item input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.p-datepicker .p-datepicker-header .p-datepicker-next, .p-datepicker .p-datepicker-header .p-datepicker-prev{
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0;
  background: transparent;
  border-radius: 50%;
  transition: background-color .2s, color .2s, box-shadow .2s;
}

.p-inputtext{
    height: 56px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}
.p-button-icon-only{
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0px 10px 10px 0px;
  padding: 0px 15px;
}
.p-button-icon-only svg{
  color: red;
}
.p-datepicker-month.p-link{
  margin: 10px !important;
}
.react-datepicker-wrapper svg{
  color: red;
}

// css for radio button starts
// .customRadioCss {
//   display: flex;
//   justify-content: space-between;
// }

.customRadioCss .trip-option {
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.customRadioCss .trip-option:hover{
  cursor: pointer;
}
.customRadioCss .trip-option.selected {
  border-color: #e91431;
  background-color: rgba(233, 20, 49, 0.03);
  color: red;
}

.customRadioCss .hidden-radio {
  display: none;
}
.customColor{
  color: #7e7e7e;
  font-size: 14px;
}
.trip-option .labelName{
  color: #7e7e7e;
}
.labelName{
  font-size: 14px;
}
.customRadioCss .trip-option.selected .labelName {
  color: red !important;
}
.p-component:disabled, .p-disabled {
  opacity: .6;
}
.p-datepicker table td.p-datepicker-today>span {
  background: #ced4da;
  color: #495057;
  border-radius: 50%;
  border-color: transparent;
}
.p-datepicker table td {
  padding: .5rem;
}
.p-datepicker table td>span.p-highlight {
  color: #4338ca;
  background: #eef2ff;
}
.p-datepicker table td>span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow .2s;
  border: 1px solid transparent;
  margin-left: -6px;
}
.p-datepicker {
  padding: .5rem;
  color: #495057;
  border-radius: 6px;
}