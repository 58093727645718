.table-wrap {
  border-radius: 6px;
  border: 1px solid $gray-300;
  overflow: hidden;
}

.table {
  margin: 0;

  th {
    font-weight: 500;
    white-space: nowrap;
    position: relative;
    height: 50px;
    border-color: $gray-300;
    @include mq("xl", "max") {
      font-size: 14px;
    }
    &.no-border {
      &:after {
        display: none;
      }
    }

    &:not(:last-child) {
      &:after {
        content: "";
        border-right: 1px solid $gray-400;
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 0;
      }
    }
  }

  th,
  td {
    padding: 14px 20px;
    vertical-align: middle;
    min-width: 160px;
    @include mq("xl", "max") {
      padding: 14px;
      min-width: 0;
    }
  }

  tbody {
    font-size: 14px;
  }

  tr {
    &:last-child {
      td {
        border: none;
      }
    }
  }
  tfoot {
    td {
      border-top: 1px solid $gray-300 !important;
    }
  }
}
