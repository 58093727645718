.two-col-layout {
  display: flex;
  gap: 20px;
  @include mq("lg", "max") {
    flex-direction: column;
  }
}

.tcl-main {
  flex: 1 1 auto;
  min-width: none;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.tcl-sidebar {
  flex: 0 0 auto;
  width: 376px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  @include mq("xl", "max") {
    width: 330px;
  }
  @include mq("lg", "max") {
    width: auto;
  }
}
