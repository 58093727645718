.form-check-input,
.form-control,
.form-select {
  box-shadow: none !important;
}

.form-control {
  padding-left: 14px;
  padding-right: 14px;
}

textarea {
  &.form-control {
    height: 80px;
    resize: vertical;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.form-control-sm {
  font-size: 14px;
  height: 40px !important;
}

.auth-placeholder {
  @media (max-width: 768px) {
    font-size: 13px;
    padding-top: 2px;
  }
}

.form-control,
.form-select {
  height: 48px;

  &.is-valid {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' fill='%23E91431'%3E%3Cpath d='M338.196 896.313v0c-22.588 0-42.667-8.784-58.981-23.843l-254.745-254.745c-32.627-32.627-32.627-84.079 0-116.706 0 0 0 0 0 0 32.627-32.627 84.079-32.627 116.706 0 0 0 0 0 0 0l197.020 197.020 544.627-545.882c32.627-32.627 84.079-32.627 116.706 0v0c32.627 32.627 32.627 84.079 0 116.706 0 0 0 0 0 0l-602.353 603.607c-16.314 16.314-36.392 23.843-58.981 23.843z'%3E%3C/path%3E%3C/svg%3E");
    background-position: right 12px center;
    background-size: 14px;

    &:not(:focus) {
      border-color: $gray-300;
    }

    &:focus {
      border-color: $primary;
    }
  }
}

.custom-dropdown-control {
  position: relative;

  .cdc-label {
    position: absolute;
    left: 13px;
    top: 12px;
    margin: 0;
    transition: all 0.1s ease-in-out;
    color: $gray-500;
    z-index: 1;
  }

  &.active {
    .cdc-label {
      color: $gray-500;
      font-size: 12px;
      background: white;
      padding: 0 4px;
      top: -8px;
      left: 9px;

      span {
        color: $primary;
      }
    }
  }
}

.input-phone-control {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 0;
  padding-left: 8px;

  .PhoneInputInput {
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 2;
    background: transparent;
    line-height: 1;
  }

  .PhoneInput {
    height: 100%;
  }

  .PhoneInputCountry {
    display: flex;
    align-items: center;
    margin-right: 10px;
    background: darken($gray-200, 3);
    border-radius: 6px;
    padding: 7px 10px 7px 7px;
    align-self: center;
  }

  .PhoneInputCountrySelectArrow {
    margin-left: 8px;
    color: $gray-900 !important;
    margin-top: -2px;
    opacity: 1;
  }

  .PhoneInputCountryIconImg {
    width: 26px;
    height: auto;
    display: block;
    border-radius: 3px;
    border: 1px solid $gray-300;
  }

  .PhoneInputCountryIcon {
    @include square(auto);
    box-shadow: none !important;
    background: none !important;
    border: none !important;
  }

  .ipc-label {
    position: absolute;
    left: 74px;
    top: 12px;
    margin: 0;
    transition: all 0.2s ease-in-out;
    color: $gray-500;
    z-index: 1;
  }

  &.focused {
    border-color: #f48a98;
  }

  &.active,
  &.focused {
    .ipc-label {
      color: $gray-500;
      font-size: 12px;
      background: white;
      padding: 0 4px;
      top: -6px;
      left: 9px;
      line-height: 1;

      span {
        color: $primary;
      }
    }
  }
}
.beneficiary-details-pa {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-top: 25px;
  padding: 30px 20px 35px 20px;
}
.floating-input {
  position: relative;

  .form-control,
  .form-select {
    &:focus ~ .form-label,
    &:not(:placeholder-shown) ~ .form-label {
      color: $gray-500;
      font-size: 12px;
      background: white;
      padding: 0 4px;
      top: -8px;
      left: 9px;

      span {
        color: $primary;
      }
    }

    &:disabled {
      &:not(:placeholder-shown) ~ .form-label {
        background: linear-gradient(to bottom, #ffffff 50%, $gray-200 50%, $gray-200 50%);
      }
    }
  }

  .form-label {
    position: absolute;
    left: 13px;
    top: 12px;
    margin: 0;
    transition: all 0.1s ease-in-out;
    color: $gray-500;
  }
}

.form-check {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 10px;
  align-items: center;

  &.form-check-inline {
    display: inline-flex;
  }

  &:not(.form-switch) {
    .form-check-input {
      font-size: 18px;
    }
  }

  .form-check-input {
    margin: 0;
    float: none;
  }

  .form-check-label {
    display: block;
  }
}

.form-check-input {
  cursor: pointer;
}

.form-switch {
  align-items: center;

  .form-check-input {
    background-image: none;
    border-width: 0;
    border-radius: 50px;
    width: 32px;
    height: 14px;
    background-color: $gray-400;
    margin: 0;
    position: relative;
    z-index: 0;
    transition: $transitionValue;

    &:after {
      content: "";
      position: absolute;
      border: none;
      z-index: 2;
      @include circle(20px);
      background-color: #fff;
      margin-top: -3px;
      box-shadow: 0px 1px 2px rgba(90, 90, 90, 0.48);
      transition: $transitionValue;
    }

    &:checked {
      background-color: rgba($primary, 0.3);

      &:after {
        background-color: $primary;
        margin-top: -3px;
        margin-left: 12px;
        box-shadow: 0px 1px 2px rgba(90, 90, 90, 0.25);
      }
    }
  }
}
.gray-color {
  background-color: $gray-100;
}
.search-form-control {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  svg {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: $gray-500;
  }
}

.id-upload-control {
}

.iduc-item {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray-300;
  border-radius: 6px;
  font-size: 14px;
  gap: 5px;
  height: 136px;
  position: relative;
}

.iduc-fig {
  width: 75px;
}

.iduc-img {
  max-width: 75%;
  max-height: 75%;
}

.iduc-input {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  @include square(100%);
  opacity: 0;
}

.iduc-remove {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 20px;
  color: $primary;
  background: white;
  border-radius: 100%;
  border: none;
  padding: 0;
  line-height: 1;
}

.radio-box {
  position: relative;
  z-index: 0;

  .form-check-input {
    position: absolute;
    left: 14px;
    top: calc(50% - 9px);
    z-index: 1;

    &:not(:checked) {
      border-color: $gray-500;
    }

    &:checked ~ .form-check-label {
      color: $primary;
      font-weight: 500;
      border-color: $primary;
      background: rgba($primary, 0.05);
    }
  }

  .form-check-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    cursor: pointer;
    border: 1px solid $gray-400;
    border-radius: 6px;
    padding: 0 14px 0 46px;
    cursor: pointer;
    height: 48px;
  }

  .rb-icon {
    font-size: 28px;
  }
}

.otp-btn {
  font-size: 12px;
  position: absolute;
  right: 8px;
  top: 8px;
  border: 1px solid $gray-300;
}

.rc-slider {
  .rc-slider-track {
    background: $primary;
  }

  .rc-slider-handle {
    background: $primary;
    border-color: white;
    @include square(15px);
    opacity: 1;

    &:hover {
      border-color: white !important;
    }

    &.rc-slider-handle-dragging {
      box-shadow: 0 0 0 5px rgba($primary, 0.5) !important;
      border-color: $primary !important;
      background: white;
    }
  }
}

.range-filter-block {
  position: relative;
  z-index: 0;
}

.chip-select-control {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 12px;
}

.csc-item {
  cursor: pointer;
  display: block;
}

.csc-input {
  &:checked + .csc-label {
    border-color: $primary;
    color: $primary;
    background: rgba($primary, 0.03);
  }
}

.csc-label {
  padding: 6px 8px;
  border-radius: 10px;
  border: 1px solid $gray-900;
  color: $gray-900;
  line-height: 1.2;
  font-size: 12px;
  display: block;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
  padding: 0;
  border: 0;
}

.search-input-custom {
  border: 1px solid $gray-300;
  font-size: medium;
}
