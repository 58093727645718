// Default style start
*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  outline: 0;
}

html,
body {
  box-sizing: border-box;
  font-family: $font-default;
  min-height: 100vh;
  font-size: 16px;
  scroll-behavior: smooth;
  word-break: break-word;
  background: $gray-200;
}

body {
  overflow-x: hidden !important;
}

a {
  cursor: pointer;
}

// Make images easier to work with
img {
  image-rendering: auto;
  shape-rendering: crispedges;
}

.h1,
h1 {
  font-size: 24px;
  font-weight: bold;
}

.h2,
h2 {
  font-size: 20px;
  font-weight: 600;
}

.h3,
h3 {
  font-size: 18px;
  font-weight: 500;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important; /* Change the color to your background color */
  color: inherit !important;
  background: white !important;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 3;
}

.disabled-text {
  color: #757575;
  opacity: 0.7;
}

.capitalize {
  text-transform: capitalize;
}
