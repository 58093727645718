.main-footer {
  background: $gray-800;
  color: white;
}
.fontAddress-footer{
  font-size: 13px;
  text-align: center;
  margin-top: 25px;
}

.mf-top {
  padding-top: 30px;
  padding-bottom: 30px;
  @include mq("lg", "max") {
    padding-top: 20px;
    padding-bottom: 0px;
  }
}

.mf-title {
  margin: 0 0 16px;
  color: $gray-500;
  font-size: 17px;
  min-height: 30px;

  &.alt {
    color: white;
    font-size: 13px;
    margin: 10px 5px;
    font-weight: normal;
    min-height: 0;

    @include mq("md", "min") {
      margin: 10px 5px;
      font-size: 13px;
    }
  }
  &.text-align {
    text-align: left;
    @include mq("md", "min") {
      text-align: right;
    }
  }
}

.mf-link {
  color: white;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.mf-mid {
  background: $gray-700;
  padding: 15px 0 20px;
  @include mq("md", "min") {
    padding: 20px 0 25px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq("md", "max") {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
}

.mf-a-inner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mf-copyright {
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mf-a-item {
  cursor: pointer;

  img {
    @include mq("md", "max") {
      width: 75px;
    }
  }
}

.mf-social {
  display: flex;
  align-items: center;
  font-size: 21px;
  gap: 15px;
  margin: 10px;
}

.mf-s-item {
  display: block;
  cursor: pointer;
  color: white;

  &:hover {
    color: $primary;
  }
}

.mf-uan {
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  @include mq("xl", "min") {
    font-size: 12px;
  }

  @include mq-only("lg", "xl") {
    align-items: flex-start;
  }

  svg {
    color: $primary;
    flex: 0 0 auto;

    @include mq-only("lg", "xl") {
      margin-top: 5px;
    }
  }

  span {
    display: flex;
    align-items: center;

    @include mq-only("lg", "xl") {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  hr {
    align-self: stretch;
    border: none;
    border-left: 1px solid white;
    margin: 0 10px;
    opacity: 0.7;

    @include mq-only("lg", "xl") {
      visibility: hidden;
    }
  }
}
