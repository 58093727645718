.payment-wizard-wrapper {
  padding: 0 0 50px;

  .tcl-main {
    @include mq("sm", "max") {
      gap: 15px;
    }

    .cbb-head {
      cursor: pointer;
    }

    @include mq("lg", "max") {
      order: 1;
    }

    .card-box-block {
      @include mq("sm", "max") {
        border-radius: 0;
      }
    }
  }

  .tcl-sidebar {
    @include mq("sm", "max") {
      padding-left: 22px;
      padding-right: 22px;
    }
  }

  .two-col-layout {
    @include mq("sm", "max") {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.pww-btn {
  min-width: 140px;
}

.pww-head {
  background: white;
  margin-bottom: 20px;
}

.payment-wizard-nav {
  display: flex;
  align-items: center;
  counter-reset: item;
  padding: 16px 0;
  gap: 10px;

  @include mq("md", "max") {
    gap: 0;
  }
}

.pwn-wrap {
  cursor: pointer;
  @include mq("md", "min") {
    display: contents;
  }

  @include mq("md", "max") {
    flex-direction: column;
    align-items: center;
    text-align: center;
    display: flex;
    width: 70px;
    gap: 10px;
  }
}

.pwn-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;

  @include mq("md", "max") {
    flex: 0 0 auto;
    gap: 0;
    align-items: flex-start;
  }

  &:not(:first-child) {
    flex: 1 1 auto;

    &:before {
      border-top: 1px solid $gray-300;
      content: "";
      flex: 1 1 auto;

      @include mq("md", "max") {
        margin: 11px -5px 0;
      }
    }
  }

  &.active {
    .pwb-bullet {
      background: $primary;
    }
  }
}

.pwb-bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: lighten($gray-500, 10);
  flex: 0 0 auto;
  @include circle(24px);
}

.pwn-label {
  flex: 0 0 auto;
  order: 2;

  @include mq("md", "max") {
    line-height: 1.4;
    font-size: 12px;
  }
}

.payment-wizard-summary {
  @include mq("xl", "max") {
    font-size: 14px;
    border: 1px solid $gray-300;
  }

  .cbb-title {
    @include mq("xl", "max") {
      font-size: 18px;
    }
  }

  .collapse {
    @include mq("lg", "min") {
      display: contents;
    }
  }

  .cbb-head {
    border-bottom: 1px solid $gray-300;
  }

  &.shrink {
    @include mq("lg", "max") {
      .cbb-head {
        border: none;
        padding-bottom: 0;
      }
    }

    .pws-foot {
      svg {
        transform: rotate(0.5turn);
      }
    }
  }
}

.pws-section {
  border-bottom: 5px solid $gray-200;
  margin: 0 -22px;
  padding: 20px 22px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}

.pws-fig {
  max-width: 110px;
  max-height: 50px;
  object-fit: contain;
  @include square(100%);
}

.pws-foot {
  display: flex;
  align-items: center;

  @include mq("lg", "min") {
    display: none;
  }

  svg {
    transition: $transitionValue;
  }
}
