// Color Vars
$primary: #e91431;
$success: #198754;

$gray-100: #f8f9fb;
$gray-200: #f5f5f5;
$gray-300: #d9d9d9;
$gray-400: #c3c3c3;
$gray-500: #7e7e7e;
$gray-600: #5a5a5a;
$gray-700: #373737;
$gray-800: #1f1f1f;
$gray-900: $gray-800;

// Other Vars
$transitionValue: all 0.3s ease-in-out;

// Typography Family
$font-default: "Poppins", sans-serif;

// define your Break Points or override bootstrap Break Points
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
