// Dropdown
.dropdown {
  .dropdown-menu {
    padding: 5px;
    font-size: 12px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid $gray-300;
  }

  .dropdown-item {
    padding: 7px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    min-height: 32px;
    &:active {
      color: initial;
      background-color: initial;
    }
    &:active:hover {
      background-color: $gray-100;
    }
  }
}

// Modal
.modal-header,
.modal-body,
.modal-footer {
  padding: 16px 20px;
}

.modal-header {
  background: $gray-100;

  .btn-close {
    width: 12px;
    height: 12px;
    background-size: 12px;
    box-shadow: none;
  }
}

.modal-title {
  font-size: 16px;
}

.modla-close {
  @include square(12px);
  background-size: 12px;
}

.modal {
  &.drawer {
    display: flex !important;
    pointer-events: none;
    padding: 0 !important;

    * {
      pointer-events: none;
    }

    .modal-dialog {
      margin: 0px;
      display: flex;
      flex: auto;
      transform: translate(25%, 0);
    }

    .modal-content {
      border: none;
      border-radius: 0px;
    }

    .modal-body {
      overflow: auto;
    }

    &.show {
      pointer-events: auto;

      * {
        pointer-events: auto;
      }

      .modal-dialog {
        transform: translate(0, 0);
      }
    }

    &.drawer-right {
      flex-direction: row-reverse;
    }

    &.drawer-left {
      &:not(.show) {
        .modal-dialog {
          transform: translate(-25%, 0);
        }
      }
    }
  }
}

// Popover
.popover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
}
.popover-body {
  padding: 10px 12px;
  line-height: 1.4;
}
