.card-box-block {
  background: white;
  border-radius: 6px;

  &.cbb-shadow {
    box-shadow: 0 3px 9px rgba(black, 0.08);
  }
}
.card-parent .react-datepicker{
  font-size: 1rem !important;
}
.card-parent .react-datepicker__time-container{
  width: 110px !important;
}
.card-parent .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: 110px !important;
  margin: 0 0 !important;
}
.cbb-body {
  padding: 20px 22px;
}
.cbb-head {
  display: flex;
  align-items: center;
  padding: 16px 22px;

  &.cbb-head-alt {
    color: $primary;
    background: rgba($primary, 0.03);
  }
}
.cbb-plan-video {
  background-color: $gray-200;
  border-radius: 5px;
}
.cbb-icon {
  color: $gray-300;
  margin-left: auto;
  font-size: 20px;
}

.cbb-title {
  margin: 0;
}

.cbb-edit {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: $gray-900;
  text-decoration: none;
  margin-left: auto;
  svg {
    font-size: 20px;
    color: $primary;
  }
}
