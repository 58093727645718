.plan-details-head {
  box-shadow: 0 3px 9px rgba(black, 0.08);
  background: white;
  border-radius: 6px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;

  @include mq("xl", "max") {
    min-height: auto;
    flex-wrap: wrap;
    padding-top: 16px;
    padding-bottom: 16px;
    row-gap: 8px;
  }

  @include mq("sm", "max") {
    border: 1px solid $gray-300;
    margin: 0 22px 30px;
  }

  > * {
    flex: 0 0 auto;

    &:not(.pdh-share) {
      @include mq("xl", "min") {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
}

.pdh-fig {
  margin: 0;
  background: url(../../../public/assets/img/ribbon.svg) no-repeat calc(100% - 3px) center;
  background-size: auto 100%;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding-right: 100px;
  padding-left: 15px;
  width: 220px;

  @include mq("xl", "max") {
    width: auto;
    padding-right: 0;
    background: none;
    order: 0;
  }

  @include mq("xxl", "max") {
    width: auto;
    padding-left: 0;
  }

  img {
    display: block;
    object-fit: contain;
    max-width: 140px;
    max-height: 60px;
    width: 100%;
  }
}

.pdh-spacer {
  width: 100%;
  padding: 0 !important;
  order: 2;

  @include mq("xl", "min") {
    display: none;
  }
}

.pdh-plan {
  @include mq("xl", "max") {
    order: 3;
  }
}

.pdh-claim {
  @include mq("xl", "max") {
    order: 1;
  }
}

.pdh-meta {
  font-size: 14px;

  b,
  span {
    display: block;
  }

  b {
    font-size: 12px;
    font-weight: 600;
  }
}

.pdh-share {
  @include mq("xl", "max") {
    order: 4;
  }

  .btn {
    height: 40px;
    font-size: 14px;
    gap: 12px;

    &:after {
      display: none;
    }
  }

  .dropdown-item {
    svg {
      font-size: 16px;
      color: $primary;
    }

    &.active,
    &:active {
      svg {
        color: white;
      }
    }
  }
}

.plan-details-wrapper {
  padding: 20px 0 50px;

  @include mq("lg", "max") {
    padding-bottom: 0;
  }

  .container {
    @include mq("sm", "max") {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .two-col-layout {
    @include mq("md", "max") {
      gap: 10px;
    }
  }

  .tcl-main {
    @include mq("md", "max") {
      gap: 10px;
    }
  }
}

.newtab-navigation button{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D9D9D9;
  border-radius: 18px;
  opacity: 1;
  padding: 5px 20px;
  margin-right: 20px;
}
.newtab-navigation .active{
  border: 1px solid #E91431;
  color: #E91431;
}
.pdw-annual {
  background: rgba($primary, 0.03);
  padding: 14px 22px;
  margin: 0 -22px;
}
.ptpWidth{
  width: 87%;
}

.pdw-plan-contribution {
  background: white;
  padding: 14px 22px;
  margin: 0 -22px;
}

.pdw-breakup-btn {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  font-size: 14px;
  height: auto;
  padding: 0;

  &[aria-expanded="true"] {
    svg {
      transform: rotate(0.5turn);
    }
  }

  svg {
    font-size: 10px;
    line-height: 1;
    transition: $transitionValue;
  }
}

.plan-features-listing {
  display: flex;
  flex-direction: column;
}

.pfl-exclusion {
  flex-wrap: wrap;
  flex-direction: row;

  .pfl-item {
    @include mq("xl", "min") {
      flex: 0 0 auto;
      width: calc(100% / 2);

      &:nth-child(odd) {
        padding-right: 40px;
      }

      &:nth-last-child(-n + 2) {
        border-bottom: none;
        padding-bottom: 0;
      }

      &:nth-child(-n + 2) {
        padding-top: 0;
      }
    }
  }
}

.pfl-inner {
  display: flex;
  gap: 14px;
}

.pfl-item {
  padding: 20px 0;
  border-bottom: 1px solid $gray-300;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  @include mq("xl", "max") {
    width: 100%;
    padding: 10px 0;
  }
}

.pfl-child {
  width: 100%;
  border-radius: 6px;
  border: 1px solid $gray-300;
  padding: 12px 28px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &:empty {
    display: none;
  }
  .pfl-title {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: -10px;
    &:before {
      content: "";
      @include circle(5px);
      background: $gray-900;
      margin-right: 7px;
    }
  }
}

.pfl-icon {
  font-size: 14px;
  color: $primary;
  line-height: 1;
  margin-top: 5px;
  flex: 0 0 auto;

  &.pfl-cross {
    font-size: 12px;
  }
}

.pfl-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;

  @include mq("xl", "max") {
    margin-bottom: 5px;
  }
}

.pfl-desc {
  font-size: 14px;
}

.pfl-coverage {
  border-radius: 6px;
  padding: 0 8px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
  align-self: flex-start;
  font-weight: 500;
  margin-top: 10px;
  border: 1px solid $gray-300;
  &.success {
    background: lighten($success, 55);
    color: darken($success, 10);
    border: none;
  }
}

.slw-item {
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  @include mq("xl", "max") {
    gap: 6px;
    padding: 15px 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }
}

.slw-chekcbox,
.slw-price {
  flex: 0 0 auto;
}

.slw-price {
  width: 100px;
}

.slw-details {
  flex: 1 1 auto;
  min-width: 0;
}

.slw-title {
  font-weight: 500;
  margin: 0 10px 10px 0;
  font-size: 16px;

  @include mq("xl", "max") {
    margin-bottom: 5px;
  }
}

.slw-desc {
  margin: 0;
  font-size: 14px;
}

.slw-price {
  font-size: 16px;
  font-weight: 500;
  text-align: right;
}

.box-listing-wrapper {
  overflow: hidden;
  border: 1px solid $gray-300;
  border-radius: 6px;
}

.blw-body {
  font-size: 14px;
}

.align-right-text {
  display: flex;
  justify-content: flex-end;
}

.blw-item {
  &:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }
}

.blw-foot {
  border-top: 1px solid $gray-300;
}

.blw-head {
  display: flex;
  align-items: center;
  background: $gray-100;
  border-bottom: 1px solid $gray-300;

  @include mq("xl", "max") {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-form-control {
    max-width: 350px;
    width: 100%;
    margin-left: auto;

    @include mq("xl", "max") {
      margin: 5px 0 0 0;
      max-width: none;
    }
  }
}

.blw-content {
  font-weight: 500;
}

.blw-title {
  font-size: 16px;
  color: $primary;
}

.blw-caption {
  font-size: 12px;
}

.blw-head,
.blw-item,
.blw-foot {
  padding: 16px 20px;
}

.plan-detail-summary,
.plan-documents {
  @include mq("xl", "max") {
    font-size: 14px;
  }

  .cbb-title {
    @include mq("xl", "max") {
      font-size: 18px;
    }
  }
}

.plan-detail-summary {
  @include mq("lg", "max") {
    box-shadow: 0 -10px 9px rgba(0, 0, 0, 0.08) !important;
    border-radius: 0;

    .cbb-head {
      display: none;
    }
  }
}

.plan-details-sidebar {
  position: sticky;
  bottom: 0;
}

.pds-mobile {
  border-bottom: 1px solid $gray-300;
}

.pds-footer {
  padding: 12px 22px;
  display: flex;
  align-items: center;
}

.pds-content {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 12px;
  line-height: 1.3;
}

.pds-btn {
  flex: 0 0 auto;
  min-width: 100px;
}

.pds-label {
  font-size: 12px;
}

.pds-cost {
  font-size: 21px;
  font-weight: 600;
  color: $primary;
}

.pds-arrow {
  color: $primary;
  font-size: 12px;
  margin-top: 16px;
}
