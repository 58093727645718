.btn {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-lg {
  height: 44px;
  font-weight: 500;
  font-size: 16px;
}

.btn-sm {
  height: 24px;
  font-size: 12px;
}

.btn-secondary {
  background: darken($gray-200, 5);
  border: darken($gray-200, 5);
  color: $gray-900;
}

.btn-download {
  display: flex;
  align-items: center;
  gap: 12px;
  color: $gray-900;
  text-decoration: none;
  line-height: 1;
  font-size: 14px;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    color: $primary;
  }

  svg {
    font-size: 16px;
  }
}

.doc-button-block {
  border: 1px solid $gray-300;
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 14px;
  max-width: 400px;
}

.dbb-doc {
  font-size: 32px;
  margin-right: 12px;
}

.dbb-download {
  color: $primary;
  font-size: 18px;
  margin-left: auto;
  cursor: pointer;
}
