.cstmr-dshbrd-wrapper {
}

.cstmr-dshbrd-bnr {
  margin-top: 20px;

  @include mq("md", "min") {
    margin-top: 0;
    background: white;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.06);
    overflow: hidden;
  }
}

.cdb-inner {
  @include mq("md", "max") {
    background: white;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    border-radius: 10px;
    padding-left: 20px;
  }
}

.cdb-inner {
  display: flex;
  align-items: center;
  gap: 30px;

  @include mq("md", "max") {
    gap: 0;
  }
}

.cdb-fig {
  margin-right: -242px;
  flex: 0 0 auto;

  @include mq("md", "max") {
    height: 135px;
    margin-right: -142px;
  }
}

.cdb-title {
  font-size: 40px;
  font-weight: 500;
  margin: 0 0 10px 0;
  line-height: 1.2;

  @include mq("lg", "max") {
    font-size: 30px;
  }

  @include mq("md", "max") {
    font-size: 20px;
  }

  strong {
    font-weight: bold;
    display: block;
  }
}

.cdb-desc {
  margin: 0;
  font-size: 14px;
  max-width: 550px;
}

.cdb-content {
  flex: 1 1 auto;
}

.cstmr-dashbrd-tile {
  border-radius: 6px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  text-decoration: none;
  color: $gray-900;
  border: 1px solid transparent;
  transition: $transitionValue;
  height: 100%;

  @include mq("md", "max") {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &:not(.disabled):hover {
    border-color: $primary;

    .cdt-icon {
      background: $primary;
      color: white;
      border-color: $primary;
    }
  }

  &.disabled {
    background: $gray-300;
    cursor: not-allowed;
    pointer-events: none;

    .cdt-icon {
      color: $gray-600;
      border-color: $gray-600;
    }

    .cdt-label {
      color: $gray-600;
    }

    .cdt-desc {
      color: $gray-600;
    }
  }
}

.cdt-icon {
  @include circle(47px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray-600;
  flex: 0 0 auto;
  transition: $transitionValue;
  font-size: 24px;
}

.cdt-label {
  font-weight: 600;
  line-height: 1.2;
  font-size: 18px;
  margin-bottom: 10px;
}

.cdt-desc {
  font-size: 14px;
  margin: 0;
}

.dashboard-page-head {
  background: $gray-200;
  padding: 25px 0;

  @include mq("md", "max") {
    padding: 16px 0;
  }
}

.dph-title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.feat-count-tile {
  border-radius: 10px;
  padding: 14px;
  height: 106px;
  border: 1px solid $gray-300;
  background: white;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  &.active {
    border-color: $primary;
    color: $primary;
    background: lighten($primary, 47);
  }

  &.disabled {
    background: $gray-200;
    color: #9f9f9f;
  }
}

.fct-title {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
}

.fct-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fct-count {
  font-size: 24px;
  font-weight: 500;
  line-height: 0;
}

.fct-icon {
  font-size: 35px;
  line-height: 0;
}

.policy-dashboard-page {
  @include mq("md", "min") {
    background: white;
  }
}

.policy-tiles-row {
  margin-top: -70px;

  @include mq("md", "max") {
    background: $gray-200;
  }
}

.ptr-inner {
  @include mq("md", "max") {
    padding: 30px 0 20px;
  }

  @include mq("md", "min") {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
}

.dshbrd-policy-wrapper {
  padding-bottom: 50px;

  @include mq("md", "max") {
    padding-top: 24px;
    background: white;
    border-radius: 27px 27px 0 0;
  }
}

.dpw-head {
  border-bottom: 1px solid $gray-300;
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;

  @include mq("md", "max") {
    flex-direction: column;
    border: none;
  }
}

.dpw-search {
  margin-left: auto;
  max-width: 280px;
  width: 100%;

  @include mq("md", "max") {
    margin-top: 24px;
    max-width: 100%;
  }
}

.dpw-tabs {
  gap: 20px;
  margin-bottom: -2px;
  font-size: 14px;

  @include mq("md", "max") {
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $gray-300;
    gap: 0;
  }

  .nav-link {
    color: $gray-900;
    padding: 0 10px 15px;
    border-width: 3px;

    &.active {
      color: $primary;
      border-color: $primary;
      font-weight: 500;
    }
  }
}

.dpw-body {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.policy-card-block {
  border: 1px solid $gray-300;
  border-radius: 6px;
  background: white;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  position: relative;
  z-index: 0;
}

.pcb-head {
  padding: 13px 20px;
  background: lighten($primary, 47);
  border-radius: 6px 6px 0 0;
  display: flex;
  gap: 20px;
}

.pcb-meta {
  min-width: 120px;

  @include mq("lg", "min") {
    min-width: 150px;
  }
}

.pcb-meta-alt {
  width: calc(50% - 5px);

  @include mq("lg", "max") {
    .pcb-label {
      font-size: 12px;
      font-weight: normal;
    }

    .pcb-value {
      font-weight: 500;
    }
  }

  @include mq("lg", "min") {
    display: flex;
    width: 100%;

    .pcb-label {
      font-weight: normal;
      font-size: 14px;
      width: 170px;
    }
  }
}

.pcb-meta-card-alt {
  width: calc(50% - 5px);

  @include mq("lg", "max") {
    .pcb-label {
      font-size: 12px;
      font-weight: normal;
    }

    .pcb-value {
      font-weight: 500;
    }
  }

  @include mq("lg", "min") {
    width: 100%;

    .pcb-label {
      font-weight: 500;
      font-size: 13px;
      width: 170px;
    }
  }
}

.pcb-meta-primary {
  color: $primary;
  margin-left: auto;
}

.pcb-label {
  font-size: 12px;
  font-weight: 600;
}

.pcb-body {
  padding: 30px 20px;
  display: flex;
  position: relative;

  @include mq("md", "max") {
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 90px;
  }
}

.pcb-fig {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 340px;

  @include mq("lg", "max") {
    width: 200px;
  }

  @include mq("md", "max") {
    margin: 0;
    width: 120px;

    img {
      margin: 0 !important;
    }
  }

  img {
    display: block;
    max-width: 130px;
    margin: 0 auto;
    max-height: 100px;
  }
}

.pcb-btn {
  width: 100%;
  max-width: 150px;
  border-color: $gray-300;
  margin: 20px 0 -10px;
  color: $gray-900;

  @include mq("md", "max") {
    position: absolute;
    bottom: 20px;
    right: 20px;
    margin: 0;
  }
}

.pcb-btn-alt {
  width: 100%;
  max-width: 150px;
  border-color: $gray-300;
  margin: 20px 0 -10px;
  background: $gray-300;
  color: $gray-900;
  height: 35px;
  font-size: 15px;
  font-weight: 600;

  @include mq("md", "max") {
    position: absolute;
    bottom: 20px;
    right: 20px;
    margin: 0;
  }
}

.pcb-manage-btn {
  width: 100%;
  max-width: 110px;
  border-color: $primary;
  background: white;
  color: $primary;
  height: 35px;
  font-size: 15px;
  font-weight: 600;
  justify-content: space-around;

  @include mq("md", "max") {
    position: absolute;
    bottom: 20px;
    right: 20px;
    margin: 0;
  }
}
.pcb-status {
  position: absolute;
  right: 22px;
  top: -12px;
  z-index: 1;
}

.pcb-status-alt {
  position: absolute;
  left: 22px;
  top: -12px;
  z-index: 1;
}

.pcb-trunk {
  min-width: 150px;
  flex: 0 0 auto;

  @include mq("md", "max") {
    min-width: 0;
    margin-left: auto;
  }
}
.pcb-trunk-alt {
  min-width: 150px;
  flex: 0 0 auto;
  align-content: flex-end;

  @include mq("md", "max") {
    min-width: 0;
    margin-left: auto;
  }
}

.pcb-manage-container {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  @include mq("md", "max") {
    min-width: 0;
    margin-left: auto;
  }
}

.pcb-cost-label {
  font-size: 12px;
  font-weight: 500;
}

.pcb-cost-value {
  font-size: 24px;
  font-weight: 600;
}

.pcb-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include mq("md", "max") {
    order: 1;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $gray-300;
    column-gap: 10px;
    row-gap: 15px;
  }

  @include mq("lg", "max") {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.pcb-content-alt {
  flex: 1 1 auto;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include mq("md", "max") {
    order: 1;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $gray-300;
    column-gap: 10px;
    row-gap: 15px;
  }

  @include mq("lg", "max") {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.contact-tb {
  text-align: center;

  .svg-icon {
    font-size: 60px;
    color: $gray-500;
  }
}

.contact-tb-mobile {
  background: white;
  padding: 16px;
  box-shadow: 0 -5px 13px rgba(black, 0.05);
  position: sticky;
  bottom: 0;
  z-index: 1;

  .svg-icon {
    font-size: 22px;
  }

  .btn {
    gap: 10px;
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
  }
}

.dashboard-profile-layout {
  > .container-mini {
    @include mq("lg", "min") {
      display: flex;
      gap: 16px;
    }

    @include mq("lg", "max") {
      display: contents;
    }
  }

  .card-box-block,
  .cbb-body {
    @include mq("lg", "max") {
      display: contents;
    }
  }

  .cbb-head {
    @include mq("lg", "max") {
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .customer-dashboard-edit-profile-btn {
    border: 1px solid $gray-300;
    padding: 16px;

    a {
      color: $gray-600;
      font: normal normal normal 14px/21px Poppins;
      letter-spacing: 0px;
    }
  }

  .user-avatar {
    @include mq("lg", "max") {
      align-items: flex-start;
    }
  }
}

.dpl-edit {
  @include mq("md", "max") {
    margin-top: -130px;
  }
}

.dpl-side {
  @include mq("lg", "min") {
    width: 180px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .container-mini {
      display: contents;
    }
  }

  @include mq("lg", "max") {
    margin-bottom: -65px;
    margin-top: 20px;
  }
}

.dpl-main {
  @include mq("md", "max") {
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -7px 17px rgba(black, 0.06);
  }

  @include mq("lg", "min") {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .container-mini {
      display: contents;
    }
  }

  @include mq("lg", "max") {
    background: white;
    padding-top: 90px;
    padding-bottom: 50px;
  }
}

.dashboard-layout-wrapper {
  @include mq("lg", "max") {
    background: white;
    padding-top: 24px;
  }

  @include mq("md", "max") {
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -7px 17px rgba(black, 0.06);
  }

  .cbb-body,
  .cbb-head {
    @include mq("lg", "max") {
      display: contents;
    }
  }

  .cbb-title {
    @include mq("lg", "max") {
      margin-bottom: 36px;
    }
  }

  .card-box-block {
    @include mq("lg", "max") {
      box-shadow: none;
    }
  }

  > .container-mini {
    @include mq("lg", "min") {
      display: flex;
      gap: 16px;
    }
  }
}

.dlw-side {
  @include mq("lg", "min") {
    width: 180px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.dlw-main {
  @include mq("lg", "min") {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.error-message {
  font-size: 12px;
  color: $primary;
}
