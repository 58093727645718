.user-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0;

  figcaption {
    font-size: 14px;
    font-weight: 500;

    @include mq("lg", "max") {
      font-size: 18px;
    }
  }
}

.uv-fig {
  position: relative;
  @include circle(80px);
  background: white;

  @include mq("lg", "max") {
    @include circle(117px);
  }

  img {
    @include circle(100%);
    border: 1px solid $gray-300;
  }

  .uv-edit {
    font-size: 20px;
    background: white;
    border-radius: 100%;
    position: absolute;
    right: 3px;
    bottom: 3px;
    display: block;
    line-height: 0;
    @include mq("lg", "max") {
      right: 5px;
      bottom: 10px;
    }
  }
  .uv-add {
    font-size: 20px;
    border-radius: 100%;
    position: absolute;
    right: 3px;
    bottom: 3px;
    display: block;
    line-height: 0;
    @include mq("lg", "max") {
      right: 5px;
      bottom: 10px;
    }
  }
}
