.page-head {
  background: white;

  .container {
    height: 50px;
    display: flex;
    align-items: center;

    @include mq("sm", "max") {
      height: 45px;
    }
  }
}

.ph-back {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: $gray-900;
  text-decoration: none;

  @include mq("sm", "max") {
    font-size: 14px;
  }

  svg {
    color: $primary;
    font-size: 16px;
    line-height: 1;

    @include mq("sm", "max") {
      font-size: 14px;
    }
  }
}

.success-toast {
  border: 1px solid #28734b !important;
  color: #28734b !important;
}
.error-toast {
  border: 1px solid red !important;
  color: red !important;
}
