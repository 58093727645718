.comparison-summary-wrapper {
  position: relative;
  z-index: 0;
  padding-bottom: 100px;
  @include mq("lg", "max") {
    padding-bottom: 50px;
  }

  &:before {
    content: "";
    background: white;
    height: 55px;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
  display: flex;
  flex-direction: column;
}

.go-back-and-list-wrapper {
  position: sticky;
  top: 55px;
  background: linear-gradient(to bottom, white 50%, whitesmoke 50%);
  z-index: 1;
}

.comparison-table-scrollable {
  flex: 1; /* Allow the scrollable area to take up the remaining space */
}

.csw-row {
  border-bottom: 1px solid $gray-300;
  display: flex;

  @include mq("lg", "max") {
    flex-wrap: wrap;
    border-radius: 6px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid $gray-300;
  }
}

.csw-head {
  .csw-row {
    align-items: flex-end;
    border-bottom: none;
    box-shadow: none;
    padding-bottom: 10px;
    border: none;
    @include mq("lg", "max") {
      background: whitesmoke;
    }
  }

  .csw-col {
    background: none !important;
    padding: 0;
    display: block;
    min-height: 0;

    &:nth-child(3) {
      @include mq("lg", "max") {
        display: none;
      }
    }
  }

  .csw-col-label {
    @include mq("lg", "max") {
      display: none;
    }
  }
}

.csw-section-head {
  min-height: 55px;
  padding: 0 22px;
  border-bottom: 1px solid $gray-300;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;

  @include mq("lg", "max") {
    border: none;
    padding: 0;
    min-height: 0;
  }
}

.csw-col {
  flex: 1 1 0;
  min-height: 55px;
  padding: 20px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;

  @include mq("lg", "max") {
    padding: 15px;

    &:nth-child(4) {
      display: none;
    }
  }

  &:nth-child(even) {
    background: rgba($primary, 0.03);
  }

  &:not(.csw-col-label) {
    font-size: 14px;
  }
}

.csw-col-label {
  width: 400px;
  flex: 0 0 auto;

  @include mq("xxl", "max") {
    width: 360px;
  }

  @include mq("xl", "max") {
    width: 250px;
  }

  @include mq("lg", "max") {
    width: 100%;
    border-bottom: 1px solid $gray-300;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 0;
  }
}

.csw-pill {
  border-radius: 6px;
  padding: 0 8px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: lighten($success, 55);
  color: darken($success, 10);
  font-size: 12px;
  cursor: pointer;
  align-self: flex-start;

  svg {
    font-size: 10px;
  }
}

.compare-package-block {
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  z-index: 1;
  border: 1px solid $gray-300;
  position: relative;
}

.cpb-item {
  width: calc(100% / 3);
  height: 116px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 20px;
  position: relative;
  z-index: 0;
  text-align: left;

  @include mq("xl", "max") {
    gap: 10px;
    padding: 0 10px;
  }

  @include mq("lg", "max") {
    width: calc(100% / 2);

    &:nth-child(3) {
      display: none;
    }

    &:nth-child(2) {
      border: none;
    }
  }

  @include mq("sm", "max") {
    flex-direction: column;
    padding: 10px;
    align-items: center;
    text-align: center;
    height: auto;
  }

  &:not(:last-child) {
    border-right: 1px solid $gray-300;
  }
}

.cpb-label {
  font-weight: 500;
  font-size: 14px;
}

.cpb-content {
  min-width: 0;
  @include mq("sm", "max") {
    width: 100%;
  }
}

.cpb-value {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cpb-price {
  color: $primary;
  font-weight: 600;
  font-size: 22px;
  margin-top: 3px;
  line-height: 1.2;

  @include mq("xl", "max") {
    font-size: 18px;
  }
}

.cpb-fig {
  display: block;
  max-width: 80px;
  max-height: 60px;
  object-fit: contain;

  @include mq("xl", "max") {
    max-width: 60px;
    max-height: 50px;
  }
  @include mq("sm", "max") {
    max-height: 40px;
    margin: auto 0;
  }
}

.cpb-remove {
  font-size: 16px;
  color: $primary;
  z-index: 1;
  right: 10px;
  top: 10px;
  cursor: pointer;
  position: absolute;
  display: block;
  line-height: 1;
}

.cpb-empty {
  font-size: 14px;
  color: darken($gray-400, 5);
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  gap: 5px;
  height: 100%;
  padding: 0;

  &:hover {
    color: $primary;
  }
}

.csw-btn {
  max-width: 120px;
  display: block;
  width: 100%;
  margin: 0 auto;
  @include mq("sm", "max") {
    height: 40px;
  }
}

.csw-title {
  padding-bottom: 44px;
  font-weight: 600;

  @include mq("xl", "max") {
    font-size: 20px;
  }
}

.csw-section {
  margin-top: 20px;

  @include mq("lg", "min") {
    border-radius: 6px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid $gray-300;
  }

  @include mq("lg", "max") {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
