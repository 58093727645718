.spinner-wrapper {
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: sticky;
  top: 10vh;

  .spinner {
    position: absolute;
    justify-content: space-between;
    width: 7rem;
    height: 3rem;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 6px;
    align-items: center;
    box-shadow: $gray-300 2px 2px 12px;
  }
}
