.takaful-search-wrapper {
  position: relative;
}

.tsl-head {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.tsl-result-msg {
  flex: 1 1 auto;

  @include mq("lg", "max") {
    font-size: 14px;
  }
}

.takaful-search-head {
  background: white;
}

.tsh-top {
  .container {
    height: 48px;
    display: flex;
    align-items: center;
  }
}

.tsh-back {
  display: block;
  color: $primary;
  line-height: 1;
  cursor: pointer;
}

.tsh-title {
  margin: 0;
  font-weight: 600;
  margin: 0 auto 0 10px;
  flex: 0 0 auto;
}

.tsh-memebr-summary {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 0 0 auto;

  @include mq("lg", "max") {
    display: none;
  }
}
.pa-grid-main {
  margin-top: -15px;
}
.grid-system-pa {
  display: grid;
  // grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 768px) {
  .grid-system-pa {
    display: flex;
    justify-content: right;
    padding-right: 25px;
    margin-top: -40px;
  }
}
.grid-system-pa .grid-flex {
  margin-left: 30px;
}
@media only screen and (max-width: 768px) {
  .grid-system-pa .grid-flex {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .mobile-pa-search {
    margin-left: 0px !important;
  }
}

.tsh-edit-search {
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 40px;
  cursor: pointer;
  color: $gray-900;
  flex: 0 0 auto;

  svg {
    color: $primary;
    line-height: 1;
    font-size: 20px;
    background: white;
    border-radius: 100px;
  }
}

.tsh-bottom {
  .container {
    height: 80px;
    display: flex;
    align-items: center;

    @include mq("lg", "max") {
      height: 50px;
    }
  }
}

.member-plan-nav {
  display: flex;
  align-items: flex-end;
  gap: 56px;
  align-self: flex-end;
  margin-right: auto;

  @include mq("lg", "max") {
    gap: 30px;
  }
}

.auto-plan-edit-nav {
  display: flex;
  margin-right: auto;
}

.auto-plan-edit-nav-sub-title {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.mpn-item {
  display: block;
  color: $gray-900;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  padding-bottom: 12px;
  cursor: pointer;

  &.active {
    border-color: $primary;

    .mpn-value {
      color: $primary;
    }
  }
}

.mpn-label {
  font-size: 12px;
}

.mpn-value {
  font-weight: 500;
  line-height: 1.2;

  @include mq("lg", "max") {
    font-size: 14px;
  }
}
.sorting-pa {
  max-width: 230px;
}
.tsb-sort {
  margin-left: 20px;
  min-width: 180px;

  @include mq("md", "max") {
    display: none;
  }

  .form-select {
    font-size: 14px;
  }
}

.tsb-filter-btn {
  height: 35px;
  padding: 0 14px;
  background: rgba($primary, 0.03);
  color: $primary;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  align-self: flex-start;
  border-radius: 6px;
  cursor: pointer;

  &.active {
    background: $primary;
    color: white;
  }

  @include mq("md", "min") {
    display: none;
  }

  @include mq("sm", "max") {
    border-radius: 6px 0 0 6px;
    margin-right: -12px;
  }
}

.tsb-monthly-toggle {
  flex: 0 0 auto;

  @include mq("lg", "max") {
    font-size: 14px;
  }
}

.takaful-search-body {
  margin: 30px 0;
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.takaful-filter-mobile {
  width: auto !important;

  .takaful-search-sidebar {
    @include mq("md", "max") {
      display: flex;
    }
  }
}

.takaful-search-sidebar {
  background: white;
  border-radius: 6px;
  width: 280px;
  flex: 0 0 auto;

  @include mq("md", "max") {
    height: 100%;
    display: none;
    flex-direction: column;
  }
}

.tss-head {
  display: flex;
  align-items: center;
  padding: 15px 12px;
  border-bottom: 1px solid $gray-300;

  @include mq("md", "max") {
    flex: 0 0 auto;
  }
}

.tss-body {
  padding: 0 12px;

  @include mq("md", "max") {
    flex: 1 1 auto;
    overflow-y: auto;
  }
}

.tss-reset {
  background: none;
  border: none;
  display: block;
  line-height: 0;
  margin-left: auto;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.takaful-search-listing {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.marginTopextraClass{
  margin-top: -60px;
}

.tss-title {
  margin: 0;
}

.tss-filter {
  padding: 20px 0;
  border-bottom: 1px solid $gray-300;
  font-size: 14px;

  &:last-child {
    border: none;
  }
}

.tss-f-title {
  font-weight: 500;
  margin: 0 0 10px 0;
  font-size: 16px;
}

.tss-f-list {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.tss-f-list .form-check .form-check-label{
  color: #515151;
}

.rfb-value {
  line-height: 1;
  text-align: center;
  font-size: 14px;
  line-height: 1;
}

.tss-f-more {
  font-size: 12px;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    font-size: 8px;
  }
}

.tss-btn {
  display: block;
  margin-top: 20px;
  width: 100%;
}

.takaful-package-block {
  padding: 16px 20px;
  background: white;
  box-shadow: 0 3px 9px rgba(#818181, 0.1);
  border-radius: 6px;
  display: flex;
  font-size: 14px;
  position: relative;
  z-index: 0;

  @include mq("xl", "max") {
    flex-wrap: wrap;
    padding-bottom: 75px;
  }

  @include mq("lg", "max") {
    padding: 16px 16px 75px;
  }
}

.tpb-feat {
  border-right: 1px solid $gray-300;
  width: 170px;
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;

  @include mq("xxl", "max") {
    width: 160px;
    padding-left: 0;
  }

  @include mq("xl", "max") {
    border: none;
    width: auto;
  }
}
.customborder-green {
  border: 1px solid #50B47E; /* Green border */
}
.bvIcon::before{
  content: "";
  background-image: url("../../../public/assets/img/bestValueSZ.png");
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 93px;
  height: 22px;
  vertical-align: middle; /* Align with the text */
}
.tpb-fig {
  margin: 30px 0;
  max-width: 116px;
  max-height: 80px;
  object-fit: contain;

  @include mq("xl", "max") {
    margin: 10px 0 16px 0;
  }
}

.tpb-title {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.tpb-plan {
  font-size: 12px;
}

.tpb-details {
  padding: 10px 30px 0;
  flex: 1 1 auto;
  min-width: 0;

  @include mq("xxl", "max") {
    padding-right: 0;
    padding-left: 20px;
  }

  @include mq("xl", "max") {
    order: 1;
    width: 100%;
    border-width: 1px 0;
    border-color: $gray-300;
    border-style: solid;
    padding: 16px 0;
    margin-top: 16px;
  }
}

.tpb-actions {
  width: 180px;
  flex: 0 0 auto;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include mq("xl", "max") {
    padding-left: 0;
    width: auto;
    margin-left: auto;
  }
}

.font-weight-tpb-label {
  font-weight: 600;
  margin-left: 25px;
  line-height: 0px;
}
.tpbBigFont{
  font-size: 20px;
  color: #4F9A76;
}
.customborderBottom{
  border-bottom: 1px dashed #D9D9D9;
  padding-bottom: 13px;
}
.headingsLabels{
  font-size: 14px !important;
}
.tpb-labeltick {
  position: relative;
  padding-left: 24px; // Add some space to make room for the icon
  display: flex; // To align the icon and text

  &::before {
    content: ""; // Necessary for the pseudo-element to display
    background-image: url("../../../public/assets/img/tick.svg");
    background-size: contain; // Adjust based on icon size
    background-repeat: no-repeat;
    width: 14px; // Adjust width based on icon size
    height: 14px; // Adjust height based on icon size
    position: absolute;
    left: 0; // Position the icon to the left
    top: 65%;
    transform: translateY(-50%); // Vertically center the icon
  }
}
.fontSizeUpdatedAuto{
  color: #515151;
  font-weight: 400;
}
.fontSizeValueUpdatedAuto{
  font-size: 18px !important;
  color: #515151;
}
.tpb-label {
  font-size: 12px;
  margin-bottom: 3px;
  @include mq("md", "max") {
    font-size: 13px;
    margin: 0;
  }
}

.tpb-value {
  font-weight: 500;
  @include mq("md", "max") {
    font-size: 13px;
  }
}

.tpb-value-heading {
  // color: $primary;
  // font-weight: 600;
  font-size: 22px;

  @include mq("md", "max") {
    font-size: 18px;
    // margin: 0;
  }
}

.tpb-pill {
  border-radius: 6px;
  padding: 0 8px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: lighten($success, 55);
  color: darken($success, 10);
  font-size: 12px;
  cursor: pointer;

  svg {
    font-size: 10px;
  }
}

.tpb-service-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tpb-service {
  .form-check-label {
    display: flex;
    font-size: 14px;
    flex: 1 1 auto;
  }

  b {
    font-weight: 500;
    width: 100px;

    @include mq("lg", "max") {
      width: 80px;
    }
  }

  span {
    font-weight: normal;
    margin-left: auto;
  }
}

.tpb-price {
  margin: 16px 0;
}

.tpb-p-label {
  font-size: 12px;
}

.tpb-p-value {
  font-size: 24px;
  font-weight: 600;
}

.tpb-cta {
  max-width: 150px;
  display: block;
  width: 100%;

  @include mq("xl", "max") {
    position: absolute;
    bottom: 16px;
    right: 20px;
  }
}

.tpb-details-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: auto;

  @include mq("xl", "max") {
    left: 20px;
    bottom: 25px;
    position: absolute;
  }

  svg {
    font-size: 8px;
  }
}

.tpb-view {
  font-size: 12px;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
}

.tpb-share {
  background: white;

  .dropdown-toggle {
    &:after {
      display: none;
    }

    svg {
      margin-right: 6px;
    }
  }

  .dropdown-item {
    svg {
      font-size: 16px;
      color: $primary;
    }

    &.active,
    &:active {
      svg {
        color: white;
      }
    }
  }
}

.tpb-compare {
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  background: lighten($primary, 25);
  border-color: lighten($primary, 25);

  svg {
    margin-right: 6px;
  }
}

.tpb-extra {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 20px;
  top: -12px;
  display: flex;
  justify-content: space-between;
}

.takaful-compare-widget {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  .container {
    @include mq("sm", "max") {
      padding: 0;
    }
  }
}

.tcw-inner {
  display: flex;
  background: white;
  box-shadow: 0px -3px 11px rgba(0, 0, 0, 0.15);

  @include mq("md", "max") {
    padding: 12px;
    gap: 12px;
    flex-wrap: wrap;
  }
}

.tcw-action {
  width: 200px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @include mq("xxl", "max") {
    padding: 0 20px;
    width: 170px;
  }

  @include mq("xl", "max") {
    width: 155px;
  }

  @include mq("md", "max") {
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }

  .btn {
    display: block;
    width: 100%;

    @include mq("md", "max") {
      width: auto;
      order: 1;
    }
  }

  a:not(.btn) {
    color: $primary !important;
    text-decoration: none !important;
    font-size: 14px;
    cursor: pointer;
    flex: 0 0 auto;
  }
}

.tcw-item {
  width: calc((100% - 200px) / 3);
  border-right: 1px solid $gray-300;
  height: 116px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  gap: 20px;
  position: relative;
  z-index: 0;

  @include mq("xxl", "max") {
    padding: 0 20px;
    width: calc((100% - 170px) / 3);
  }

  @include mq("xl", "max") {
    gap: 10px;
    width: calc((100% - 155px) / 3);
  }

  @include mq("lg", "max") {
    width: calc((100% - 155px) / 2);

    &:nth-child(3) {
      display: none;
    }
  }

  @include mq("md", "max") {
    border: 1px solid $gray-300;
    border-radius: 6px;
    width: calc((100% - 12px) / 2);
    height: 80px;
  }
  @include mq("sm", "max") {
    padding-left: 10px;
  }
}

.tcw-label {
  font-weight: 500;
  font-size: 14px;

  @include mq("md", "max") {
    display: none;
  }
}

.tcw-value {
  font-size: 12px;
}

.tcw-price {
  color: $primary;
  font-weight: 600;
  font-size: 22px;
  margin-top: 8px;
  line-height: 1.2;

  @include mq("md", "max") {
    font-size: 18px;
    margin: 0;
  }
}

.tcw-fig {
  display: block;
  max-width: 80px;
  max-height: 60px;
  object-fit: contain;

  @include mq("xl", "max") {
    max-width: 60px;
    max-height: 50px;
  }
  @include mq("sm", "max") {
    max-width: 35px;
    max-height: 35px;
  }
}

.tcw-remove {
  font-size: 20px;
  color: $primary;
  z-index: 1;
  right: 10px;
  top: 10px;
  cursor: pointer;
  position: absolute;
  display: block;
  line-height: 1;

  @include mq("xxl", "max") {
    font-size: 16px;
    right: 5px;
    top: 5px;
  }
  @include mq("md", "max") {
    right: -5px;
    top: -7px;
    background: white;
  }
}

.tcw-empty {
  font-size: 14px;
  color: darken($gray-400, 5);
  width: 100%;
  text-align: center;
  @include mq("sm", "max") {
    margin-left: 10px;
  }
}

// Services modal

.services-modal {
  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Text Message Modal

.tmm-phone {
  position: relative;
  z-index: 0;
}

.tmm-p-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}

// Edit Search Modal

.edit-search-modal {
  .hero-wizard-block {
    box-shadow: none;
    width: auto;
    padding: 0;
    margin: 0;
    position: static;
  }
  .htph-data {
    display: none;
  }
  .hwb-head {
    padding: 0;
  }
  .hwb-inner {
    padding: 0;
  }
  .hwb-title {
    small {
      display: none;
    }
  }
}


// SME PAGE CSS STARTS
.smeBottomBox{
  color: #515151;
  margin-top: -90px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  opacity: 1;
  width: 90%;
}
.aboutPlatform {
  font-size: 20px;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 18px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 13px;
    // from 0px to 768px
  }
}
.aboutPlatform span{
  font-weight: 700;
}
.pricingSME {
  color: #E91431;
  font-size: 40px;
  font-weight: 600;

  @include mq("xl", "max") {
    font-size: 35px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 20px;
    // from 0px to 768px
  }
}
.SMEcoverages {
  font-size: 16px;
  font-weight: 600;
  color: #5A5A5A;

  @include mq("xl", "max") {
    font-size: 14px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 10px;
    // from 0px to 768px
  }
}
.borderRight{
  border-right: 1px solid #5A5A5A;
}
.bgCustomCss{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #0000000F;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  opacity: 1;
}
.bgCustomCss:hover{
  border: 1px solid #E91431;
  transition: border .3s ease-in-out;
}
.imgLabel{
  display: flex;
  flex-direction: column;
}
.imgLabel img{
  width: 45px;
  height: 41px;
}
.imgLabel label{
  font-size: 14px;
  font-weight: 300;
  color: #515151;
  text-align: left;
  padding: 15px 0px;
}
.radio-input:checked{
  color: red !important;
  accent-color: red; /* Changes the color of the radio button when checked */
}
.widthCustomCss{
  width: 63%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 768px){
  .widthCustomCss{
    width: 100%;
  }
}
.pricingCoverage{
  color: #E91431;
  font-size: 30px
}
.ecPricing{
  font-size: 14px;
  font-weight: 600;
}
.btnWidthCustom{
  width: 100%;
}
.customwidthform{
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 768px){
  .customwidthform{
    width: 100%;
  }
}
.customWidthformchild{
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 768px){
  .customWidthformchild{
    width: 65%;
  }
}
.heroBanner-sme {
  background: url(../../../public/assets/img/smeBanner.png) no-repeat center center;
  background-size: cover;
  min-height: 700px;

  @include mq("lg", "max") {
    background: $gray-200;
    min-height: 0;
  }
}
.heroBanner-smeContainer {
  padding-top: 100px;
}
@media only screen and (max-width: 768px) {
  .heroBanner-smeContainer {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.sme-h1 {
  margin: 0 0 5px 0;
  font-size: 50px;
  font-weight: 600;

  @include mq("xl", "max") {
    font-size: 36px;
  }

  @include mq("md", "max") {
    font-size: 36px;
    font-weight: 500;
    margin: 0 0 3px 0;
  }
}
.getQuoteSme{
  width: 35%;
  height: 51px;
}
.cyc-title{
  font-size: 26px;
  font-weight: 700;
    @include mq("md", "max") {
      font-size: 22px;
    }
}
.cyc-caption{
  font-size: 16px;
}

.slick-prev:before, .slick-next:before{
  font-size: 45px !important;
  opacity: 0;
}
.slick-prev , .slick-prev:hover, .slick-prev:focus{
  left: 17px;
  z-index: 1;
  height: 85px;
  width: 85px;
  background-image: url("../../../public/assets/img/newsliderArrowSmeLeft.svg");
  background-repeat: no-repeat;
}
@media only screen and (max-width:768px){
  .slick-prev, .slick-prev:hover, .slick-prev:focus{
    left: 8px;
  }
}
.slick-next , .slick-next:hover, .slick-next:focus{
  right: 22px;
  z-index: 1;
  height: 85px;
  width: 85px;
  background-image: url("../../../public/assets/img/newsliderArrowSme.svg");
  background-repeat: no-repeat;
}
@media only screen and (max-width:768px){
  .slick-next , .slick-next:hover, .slick-next:focus{
    right: 8px;
  }
}
.planBoxes{
  width: 85% !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  opacity: 1;
  padding: 50px 20px;
  height: 460px;
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
}
@media only screen and (max-width:992px) {
  .planBoxes{
    width: 100% !important;
    height: 500px;
  }
}
.planBoxes.active-slide , .planBoxes:hover {
  height: 490px;
  width: 85.5% !important;
  border: 1px solid #E91431;
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
}
@media only screen and (max-width:992px){
  .planBoxes.active-slide {
    width: 94% !important;
    margin-left: 3%;
    height: 510px;
  }
}
.planCoverages{
  font-size: 12px;
  color: #515151;
  font-weight: 600;
  transition: font-size 0.3s ease-in-out;
}
.planBoxes.active-slide .planCoverages , .planBoxes:hover .planCoverages {
  font-size: 14px;
  @include mq("xl", "max") {
    font-size: 14px;
  }

  @include mq("md", "max") {
    font-size: 12px;
  }
}
.slick-list{
  padding: 0px 80px !important;
}
@media only screen and (max-width: 768px){
  .slick-list{
    padding: 0px 60px !important;
  }
}
.planCoveragesPrice{
  font-size: 16px;
  color: #50B47E;
  padding-bottom: 14px;
}
.limitRs{
  font-size: 30px;
  color: #50B47E;
  font-weight: 600;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 16px;
}
.limitRs span{
  font-size: 16px;
  font-weight: 400;
}
.plansHead{
  font-size: 18px;
  color: #E91431;
  font-weight: 500;
  transition: font-size 0.3s ease-in-out;
}
.planBoxes.active-slide .plansHead , .planBoxes:hover .plansHead {
  font-size: 24px;
}
.getStartedBtn{
  width: 100%;
  padding: 13px 0px;
}
.inactive-btn {
  opacity: 0.5; /* Visually indicate the button is inactive */
  cursor: not-allowed; /* Optional: change the cursor to indicate inactivity */
}

.planBoxes:hover .inactive-btn {
  opacity: 1; /* Allow full visibility on hover */
  cursor: pointer; /* Optional: allow clicking behavior */
}
.navItems:hover{
  margin-top: 0px !important;
}
.customNavTabs{
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 992px){
  .customNavTabs{
    width: 80%;
  }
}
.mycustomTabs{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D9D9D9 !important;
  border-radius: 5px;
  opacity: 1;
}
.mycustomTabs.active-tab{
  background: #E91431 0% 0% no-repeat padding-box !important;
  color: white !important;
}
.thanksBox{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001A;
  border: 1px solid #D9D9D9;
  border-radius: 15px;
  opacity: 1;
  height: auto;
}
.thankyouImg{
  margin-top: -90px;
}
.thankyouAnchor{
  color: white;
  text-decoration: none;
}
.thankyouBtn{
  margin-left: auto;
  margin-right: auto;
}
.thankshead{
  font-size: 30px;
}
.thankshead span{
  color: #E91431;
  font-weight: 700;
}
.thanksParas{
  font-size: 16px;
}
.thanksParas span{
  color: #E91431;
}
.tipestimatedCon{
  font-size: 12px;
  color: #515151;
}
// SME PAGE CSS ENDS

// SOLAR PAGE CSS START
.solarheadingH1{
  font-size: 35px;
}
.solarheadingH1 span{
  color: #E91431;
}
.solarheadingH3{
  font-size: 16px;
  color: #515151;
}
// SOLAR PAGE CSS END