.invoice-summary-wrapper {
  background: white;
  padding-bottom: 50px;
  position: relative;
  z-index: 0;

  @include mq("sm", "max") {
    font-size: 14px;
    padding-bottom: 0;
    .container {
      padding-left: 0;
      padding-right: 0;
    }
    .page-head {
      padding: 0 8px;
    }
  }
}

.invoice-summary-banner {
  background: #f5f5f6;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;

  @include mq("sm", "max") {
    height: 255px;
  }

  &:before {
    content: "";
    background: url(../../../public/assets/img/invoice-bnr-left.jpg) no-repeat center;
    width: 170px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background-size: contain;
    background-position: left center;
  }

  &:after {
    content: "";
    background: url(../../../public/assets/img/invoice-bnr-right.jpg) no-repeat center;
    width: 170px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    background-size: contain;
    background-position: right center;
  }

  .page-head {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
  }
}

.isb-content {
  padding: 0 0 0 80px;
  display: flex;
  align-items: flex-start;
  gap: 32px;

  @include mq("xl", "max") {
    padding: 0;
  }

  @include mq("lg", "max") {
    margin: 0 auto auto;
  }

  @include mq("sm", "max") {
    gap: 25px;
    margin-top: 10px;
  }

  img {
    @include mq("lg", "max") {
      height: 150px;
    }

    @include mq("sm", "max") {
      height: 100px;
    }
  }
}

.isb-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.2;

  strong {
    font-size: 47px;
    font-weight: 600;
    color: $primary;
    display: block;
  }

  @include mq("lg", "max") {
    font-size: 16px;

    strong {
      font-size: 28px;
    }
  }

  @include mq("sm", "max") {
    strong {
      font-size: 24px;
    }
  }
}

.isb-caption {
  margin-top: 5px;

  @include mq("lg", "max") {
    font-size: 14px;
  }

  @include mq("sm", "max") {
    max-width: 130px;
  }
}

.isb-btn {
  margin-top: 20px;

  @include mq("sm", "max") {
    display: none;
  }
}

.isb-download {
  background: white;
  padding: 15px;
  position: sticky;
  width: 100%;
  bottom: 0;
  box-shadow: 0 -5px 13px rgba(black, 0.05);
  z-index: 2;
  margin-top: 25px;
  @include mq("sm", "min") {
    display: none;
  }
}

.isb-inner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.isw-invoice {
  padding-left: 0;
  padding-right: 0;
}

.isw-main {
  display: flex;
  align-items: flex-start;
  gap: 34px;

  @include mq("lg", "max") {
    flex-direction: column;
    gap: 20px;
  }
}

.isw-content {
  flex: 1 1 auto;
  margin-top: 25px;

  @include mq("lg", "max") {
    order: 1;
    margin-top: 0;
  }

  @include mq("sm", "max") {
    padding: 0 22px;
  }
}

.isw-box {
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(black, 0.08);
  width: 475px;
  flex: 0 0 auto;
  padding: 24px 24px 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: -250px;

  @include mq("xl", "max") {
    width: 400px;
  }

  @include mq("lg", "max") {
    width: 100%;
    margin: -75px 0 0 0;
    border-radius: 20px;
  }

  @include mq("sm", "max") {
    margin: -75px 0 0 0;
    box-shadow: 0 -4px 9px rgba(black, 0.08);
    border-radius: 20px 20px 0 0;
  }
}

.isw-btn {
  font-size: 14px;
  height: 32px;
}

.isw-total {
  background: rgba(233, 20, 49, 0.03);
  padding: 14px 22px;
  margin: 0 -24px;
}

.isw-item {
  padding: 24px;
  margin-left: -24px;
  margin-right: -24px;

  &:not(:last-child) {
    border-bottom: 5px solid $gray-100;
  }
}

.isw-head {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.isw-body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @include mq("sm", "max") {
    gap: 3px;
  }
}

.isw-fig {
  margin: 15px 0;

  img {
    max-width: 100px;
    max-height: 60px;
  }
}
