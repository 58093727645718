// Frequently Used Classes start
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.w-75 {
  width: 75%;
}
.text-custom-gray {
  color: #5a5a5a;
}
.pt-100-small-none {
  padding-top: 100px;
}
@media only screen and (max-width: 768px) {
  .pt-100-small-none {
    padding-top: 0px;
  }
}
// Frequently Used Classes end

.landing-page-wrapper {
  background: white;
}

// <Health Takaful Plan Hero>

.health-takaful-plan-hero {
  background: url(../../../public/assets/img/htp-banner.png) no-repeat center bottom #808285;
  background-size: cover;
  min-height: 450px;

  @include mq("lg", "max") {
    background: $gray-200;
    min-height: 0;
  }
}

.auto-takaful-plan-hero {
  background: url(../../../public/assets/img/newautoBanner.png) no-repeat center bottom #808285;
  background-size: cover;
  min-height: 720px;

  @include mq("lg", "max") {
    background: $gray-200;
    min-height: 0;
  }
}

.htph-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq("lg", "max") {
    flex-direction: column;
  }
  @include mq("sm", "max") {
    padding-left: 0;
    padding-right: 0;
  }
}

.htph-content {
  display: flex;
  align-items: flex-start;
  margin: 36px 0 0 0;
  align-self: flex-end;

  @include mq("lg", "max") {
    align-self: auto;
    max-width: 540px;
    width: 100%;
  }

  @include mq("md", "max") {
    display: none;
  }
}

.htph-img {
  display: block;

  @include mq("lg", "max") {
    display: none;
  }
}

.htph-data {
  width: 310px;
  margin: 87px 0 0 0px;

  @include mq("xl", "max") {
    margin: 0px 0 0 -105px;
  }

  @include mq("lg", "max") {
    margin: 0;
    width: 100%;
  }

  @include mq("md", "max") {
    margin: 0 0 20px 0;
  }
  @include mq("sm", "max") {
    padding: 0 20px;
  }
}

.htph-title {
  margin: 0 0 5px 0;
  font-size: 30px;

  @include mq("xl", "max") {
    font-size: 24px;
  }

  @include mq("md", "max") {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 3px 0;
  }
}

.htph-caption {
  width: 250px;
  margin: 0;

  @include mq("lg", "max") {
    width: auto;
    font-size: 14px;
  }
}

// </Health Takaful Plan Hero>

// <Health Takaful Plan Hero Wizard>
.hero-wizard-block-extraClass{
  background-color: #fff !important;
  margin: 6% 0 !important;
}

.travel-takaful-plan-hero .hero-wizard-block{
  position: absolute;
  top: 17%;
  right: 9%;
  @include mq("lg", "max") {
    position: static;
  }
}

.hero-wizard-block {
  background: transparent;
  box-shadow: none;
  border-radius: 5px;
  padding: 24px 20px;
  width: 385px;
  position: relative;
  z-index: 0;
  margin: 20px 0;

  @include mq("lg", "max") {
    border-radius: 20px 20px 0 0;
    margin-bottom: 0;
    box-shadow: none;
    width: 540px;
  }

  @include mq("md", "max") {
    background: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
  }
}

.hero-wizard-block-no-shadow {
  @extend .hero-wizard-block;
  box-shadow: none !important;
  // background:rgba(250,250,250,255);
  background: none !important;
  margin-bottom: 15px;
  border: none !important; /* This removes the border */
}

.hwb-inner {
  @include mq("md", "max") {
    padding: 30px 20px;
    background: white;
    border-radius: 20px 20px 0 0;
  }
}

.hwb-gender-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hwb-gt-item {
  label {
    border-radius: 6px;
    height: 32px;
    border: 1px solid $gray-300;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    cursor: pointer;
    width: 90px;
  }

  input:checked + label {
    height: 34px;
    background: $primary;
    color: white;
    border-color: $primary;
    position: relative;
    z-index: 1;
  }

  input:not(:checked) + label:hover {
    background: $gray-100;
  }

  &:first-child input:not(:checked) + label {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -2px;
  }

  &:last-child input:not(:checked) + label {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -2px;
  }
}

.hwb-ms-item {
  height: 56px;
  border: 1px solid $gray-300;
  border-radius: 6px;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    border-color: $primary;
    color: $primary;
  }

  > * {
    flex: 0 0 auto;
  }

  svg {
    font-size: 29px;
  }

  &.active {
    color: $primary;
    border-color: $primary;
    background: rgba($primary, 0.03);

    .hwb-ms-input {
      color: $primary;
    }

    .hwb-ms-input {
      display: block;
    }

    .hwb-ms-btn {
      display: flex;
    }
  }
}

.hwb-ms-input {
  border: none;
  background: none;
  padding: 0;
  font-size: 16px;
  min-width: 0;
  display: block;
  flex: 1 1 auto;
  text-align: right;
  display: none;
}

.hwb-ms-btn {
  flex-direction: column;
  gap: 3px;
  width: 18px;
  margin-left: 2px;
  display: none;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    background: lighten($primary, 20);
    color: white;

    svg {
      font-size: 8px;
    }

    &:first-child {
      border-radius: 2px 2px 0 0;
    }

    &:last-child {
      border-radius: 0 0 2px 2px;
    }
  }
}

.hwb-btn {
  margin-top: 24px;
  display: block;
  width: 100%;
}

.hwb-head {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;

  @include mq("md", "max") {
    align-items: flex-start;
  }
  @include mq("sm", "max") {
    padding: 0 20px;
  }
}

.hwb-back {
  color: $primary;
  cursor: pointer;
  display: block;
  line-height: 1;

  @include mq("md", "max") {
    margin-top: 2px;
  }
}

.hwb-title {
  small {
    font-size: 14px;
    display: block;
    font-weight: normal;
    margin-top: 5px;

    @include mq("md", "min") {
      display: none;
    }
  }
}

.hwb-age-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.hwb-age-field {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.hwb-age-input {
  flex: 1 1 auto;
}

.hwb-age-label {
  width: 150px;
  text-transform: capitalize;
}

.hwb-age-error {
  width: 100%;
}

.hwb-accept-terms {
  .form-check-label {
    font-size: 12px;

    a {
      text-decoration: underline;
    }
  }
}

// </Health Takaful Plan Hero Wizard>

// <My Health Takaful Banner>

.my-health-takaful-bnr {
  padding: 60px 0 0;

  .container {
    @include mq("xl", "min") {
      max-width: 1020px;
    }
  }
}

.mhtb-title {
  margin-bottom: 10px;
}

// </My Health Takaful Banner>

// <Why Choose Us Section>

.why-choose-us-section {
  padding: 60px 0;
}

.wcus-title {
  margin: 0 0 45px 0;
  text-align: center;
}

.wcus-item {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;

  img {
    height: 155px;
    width: auto;
    object-fit: contain;
  }
}

.wcus-label {
  margin: 20px 0 10px;
  font-size: 18px;
  font-weight: bold;
}

// </Why Choose Us Section>

// <Covered Health Takaful>

.covered-health-takaful-section {
  padding: 60px 0;
  background: $gray-200;
}

.chts-title {
  text-align: center;
  margin: 0 0 30px 0;
}

.chts-inner {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 30px;

  @include mq("sm", "max") {
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 15px;
  }
}

.chts-item {
  padding: 16px;
  border-radius: 5px;
  border: 1px solid $gray-400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  flex: 0 0 auto;
  width: calc((100% / 3) - 20px);
  position: relative;
  overflow: hidden;

  @include mq("lg", "max") {
    width: calc((100% / 2) - 15px);
  }

  @include mq("sm", "max") {
    width: 280px;
  }

  &:hover {
    .chts-desc {
      visibility: visible;
      opacity: 1;
      background-color: #fc98a6;
      color: white;
    }
  }
}

.chts-label {
  font-size: 18px;
  font-weight: bold;

  @include mq("md", "max") {
    font-size: 16px;
  }
}

.chts-fig {
  max-width: 203px;
  display: block;
  margin-bottom: 10px;
  height: 120px;
  width: auto;
  object-fit: contain;
}

.chts-desc {
  position: absolute;
  padding: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: rgba($gray-300, 0.95);
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: $transitionValue;
}

// </Covered Health Takaful>

// <Health Takaful Claim Section>

.health-takaful-claim-section {
  padding: 60px 0;
}

.htcs-title {
  text-align: center;
  margin: 0 0 45px 0;
}

.htcs-fig {
  display: block;
  margin: 0 auto;
}

// </Health Takaful Claim Section>

// <Our Partners Section>

.our-partners-section {
  padding: 24px 0;
  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      margin: 0 25px;
      @include mq("lg", "max") {
        margin: 0 20px;
      }
    }
  }
}

.ops-title {
  text-align: center;
  margin: 0 0 20px 0;
}

.ops-fig {
  display: block;
  margin: 0 auto;
  height: 80px;
  object-fit: contain;
  filter: grayscale(100%);
  transform: scale(0.9);
  transition: $transitionValue;

  &:hover {
    filter: grayscale(0%);
    transform: scale(1);
  }
}

// </Our Partners Section>

// <Download App Section>

.download-app-section {
  background: url(../../../public/assets/img/pattern.svg) repeat;
  padding: 25px 0;
  background-size: 100px;
}

.das-inner {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq("md", "max") {
    flex-wrap: wrap;
    column-gap: 20px;
  }
}

.das-fig {
  @include mq("md", "max") {
    display: none;
  }
}

.das-title {
  margin: 0 16px;

  @include mq("md", "max") {
    width: 100%;
    margin: 0 0 18px 0;
    text-align: center;
  }
}

.das-app {
  height: 45px;
  margin-left: 25px;
  cursor: pointer;
  @include mq("md", "max") {
    height: 55px;
    margin: 0;
  }
}

// <Download App Section>
.pac-title,
.mhtb-title,
.wcus-title,
.chts-title,
.ops-title,
.das-title {
  @include mq("md", "max") {
    font-size: 22px;
  }
}
.auto-banner-label-insured {
  font-size: 14px;
}

// Sehat Zamin page CSS start
.heroBanner-sehat {
  background: url(../../../public/assets/img/sehatZaminBg.png) no-repeat center bottom #808285;
  background-size: cover;
  min-height: 615px;

  @include mq("lg", "max") {
    background: $gray-200;
    min-height: 0;
  }
}
.heroBanner-sehatContainer {
  padding-top: 100px;
}
@media only screen and (max-width: 768px) {
  .heroBanner-sehatContainer {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.sehat-zaminBtn {
  width: 37% !important;
}
@media only screen and (max-width: 1200px) {
  .sehat-zaminBtn {
    width: 45% !important;
  }
}
.sz-heroimg {
  height: 497px;
  width: 521px;
}
@media only screen and (max-width: 768px) {
  .sz-heroimg {
    display: none;
  }
}
.sehat-tabs {
  color: #515151;
  padding: 19px 20px 19px 20px;
  font-weight: 800;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  opacity: 1;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.sehat-tabs.active {
  border: 2px solid #e91431;
  background-color: white !important;
  color: #e91431 !important;
  font-weight: 700;
}

.sehat-tabs .sz-btn {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: -1;
  margin-top: 17px;
  position: absolute;
  margin-left: 26px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #e91431;
  color: white;
  text-decoration: none;
}
.nav-item:hover .sz-btn {
  opacity: 1;
  visibility: visible;
}
@media only screen and (min-width: 768px) {
  .nav-item {
    transition: margin-top 0.3s ease-in-out !important; /* Adjust the duration as needed */
  }

  .nav-item:hover {
    margin-top: -10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .sz-btn {
    display: none;
  }
}
.fonthoslim-sz {
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .fonthoslim-sz {
    font-size: 10px;
  }
}
.fonthoslim-sz-contribution {
  font-size: 14px;
  color: #50b47e;
}
@media only screen and (max-width: 768px) {
  .fonthoslim-sz-contribution {
    font-size: 11px;
    color: #50b47e;
  }
}
.fontweight600 {
  font-weight: 500 !important;
  background-color: #50b47e;
  color: white;
  padding: 6px 10px;
  border-radius: 10px 10px 0px 0px;
}
@media only screen and (max-width: 768px) {
  .fontweight600 {
    padding: 6px 0px;
  }
}
.fontPackages-sz {
  font-size: 20px;
  font-weight: 600;
}
.font-szRs {
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.font-szRss {
  font-size: 14px;
  font-weight: 400;
  color: #50b47e;
}
@media only screen and (max-width: 768px) {
  .width-tabs-mobile {
    // width: 75%;
    // margin-left: auto;
    // margin-right: auto;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }
}
.customWidthSehat{
  width: 19%;
  margin-right: 1%;
  @include mq("md", "max") {
    width: 100%;
    // from 0px to 768px
  }
}
.best-value-pill {
  background-color: #e91431;
  font-size: 13px;
  border-radius: 5px;
  padding: 0px 6px;
  margin-top: -9px;
  margin-left: 55px;
}
@media only screen and (max-width: 768px) {
  .best-value-pill {
    margin-left: 40%;
    display: none;
  }
}

.sehatzamin-pricingDiscount {
  width: 90%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #50b47e;
  border-radius: 10px;
  opacity: 1;
  padding: 0px 0px 19px 0px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.sehatzamin-pricingDiscount:hover {
  border-color: #2ed06e;
  box-shadow: 0 0 0 2px #2ed06e;
}
.row-width-sz {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.sehatzamin-packages {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 5px;
  opacity: 1;
  margin: 30px 0 0 0;
  padding: 28px 23px;
  border: 1px solid #d9d9d9;
}
.sehatzaminRuppees {
  background: #e0f9ed 0% 0% no-repeat padding-box;
  border-radius: 6px;
  padding: 3px 9px;
  color: #50b47e;
  font-size: 18px;
  font-weight: 600;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 14px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 12px;
    // from 0px to 768px
  }
}

@media only screen and (max-width: 768px) {
  .newPackageClass {
    padding: 0px 23px !important;
  }
}

.HIP-title {
  font-size: 26px;
  font-weight: 600;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 14px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 12px;
    // from 0px to 768px
  }
}
.new-candb-title {
  font-size: 18px;
  font-weight: 600;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 14px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 12px;
    // from 0px to 768px
  }
}

.newcb-title {
  font-size: 36px;
  font-weight: 600;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 18px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 16px;
    // from 0px to 768px
  }
}
.candb-title {
  font-size: 16px;
  font-weight: 600;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 14px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 12px;
    // from 0px to 768px
  }
}
.new-candb-caption {
  font-size: 16px;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 12px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 10px;
    // from 0px to 768px
  }
}
.candb-caption {
  font-size: 14px;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 12px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 10px;
    // from 0px to 768px
  }
}
.border-bottom-ruppees {
  border-bottom: 1px solid #d9d9d9;
}
.exclusion-section {
  padding: 60px 0;
  background: #f5f5f5;
}
.exclusion-sz {
  background: #fff3f5 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}
.exclusion-package {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0588235294);
  border-radius: 8px;
  opacity: 1;
  margin: 60px 0 0 0;
  padding: 22px 17px;
  border: 1px solid #d9d9d9;
}
.padding-hospital {
  padding: 50px;
  border-radius: 20px;
}
@media only screen and (max-width: 768px) {
  .padding-hospital {
    padding: 50px 10px 50px 10px;
    border-radius: 0px;
  }
}
.hospitalImg-sz {
  width: 246px;
  height: 205px;
}

.hospitalHeading-sz {
  font-size: 26px;
  font-weight: 700;
  @include mq("xl", "max") {
    font-size: 20px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 20px;
    // from 0px to 768px
  }
}
.hospitalCaption-sz {
  font-size: 14px;
  @include mq("xl", "max") {
    font-size: 12px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 12px;
    // from 0px to 768px
  }
}
.padding-hospital-h3 {
  padding-right: 50px;
}
@media only screen and (max-width: 768px) {
  .padding-hospital-h3 {
    padding-right: 0px;
  }
}
.align-hospital-center {
  align-content: center;
}
@media only screen and (max-width: 768px) {
  .downloadListBtn {
    margin-left: auto;
    margin-right: auto;
  }
}

.sehatZamin-h1 {
  margin: 0 0 5px 0;
  font-size: 50px;

  @include mq("xl", "max") {
    font-size: 36px;
  }

  @include mq("md", "max") {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 3px 0;
  }
}
.sehatZamin-h2 {
  margin: 0 0 5px 0;
  font-size: 32px;

  @include mq("xl", "max") {
    font-size: 25px;
  }

  @include mq("md", "max") {
    font-size: 19px;
    font-weight: 600;
    margin: 0 0 3px 0;
  }
}
.sehatZamin-tab-h2 {
  margin: 0 0 5px 0;
  font-size: 30px;
  color: #50b47e;

  @include mq("xl", "max") {
    font-size: 25px;
  }

  @include mq("md", "max") {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 3px 0;
  }
}

.sz-anchor {
  color: white;
  text-decoration: none;
}
.sehatZamin-caption {
  font-size: 18px;

  @include mq("xl", "max") {
    font-size: 16px;
  }

  @include mq("md", "max") {
    font-size: 16px;
  }
}
.SZ-caption-color {
  color: #515151;
  font-weight: 500;
}
.sehatZamin-accordion {
  margin: 30px 0 30px 0;
}
.tickmark-title::before {
  content: "";
  display: inline-block;
  background-image: url("../../../public/assets/img/sehatZamin-tick.png"); /* Replace with the correct path */
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px; /* Adjust as needed */
  height: 10px; /* Adjust as needed */
  margin-right: 10px; /* Space between the image and text */
  vertical-align: middle; /* Align with the text */
}
.crossmark-title::before {
  content: "";
  display: inline-block;
  background-image: url("../../../public/assets/img/sehatZamin-cross.png"); /* Replace with the correct path */
  background-size: contain;
  background-repeat: no-repeat;
  width: 11px; /* Adjust as needed */
  height: 11px; /* Adjust as needed */
  margin-top: -3px;
  margin-right: 10px; /* Space between the image and text */
  vertical-align: middle; /* Align with the text */
}
.efuLogoHemayah {
  width: 80px;
  height: 113px;
}
@media only screen and (max-width: 768px) {
  .efuLogoHemayah {
    width: 42px;
    height: 55px;
  }
}
.rightefuLogoGeneral {
  border-right: 1px solid #707070;
}
.efuLogoGeneral {
  width: 86px;
  height: 110px;
}
@media only screen and (max-width: 768px) {
  .rightefuLogoGeneral {
    border-right: 1px solid #f5f5f5;
  }
  .efuLogoGeneral {
    width: 46px;
    height: 55px;
    border-right: 1px solid #f7f7f7;
  }
}
.semi-bold-italic {
  font-weight: 600; /* Semi-bold */
  font-style: italic; /* Italic */
}
@media only screen and (min-width: 768px) {
  .w-md-75 {
    width: 75%;
  }
}

.candb-title button {
  font-size: 16px;
  font-weight: 600;
  // 1200px onwards

  @include mq("xl", "max") {
    font-size: 14px;
    // from 768px to 1200px
  }

  @include mq("md", "max") {
    font-size: 12px;
    // from 0px to 768px
  }
}
.font-hoslim {
  font-size: 14px;
}
// Sehat Zamin page CSS end

// Personal Accident css start
.exclusion-pa {
  background-color: white !important;
}
.faq-pa {
  background-color: #fff3f5;
}
.keyFeatures-pa {
  background-color: #fff3f5;
}

.pa-kf-box {
  height: 120px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: #ffffff 0% 0% no-repeat;
  background: #ffffff 0% 0% no-repeat;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  opacity: 1;
}
.pa-kf-heading {
  font-size: 16px;
  font-weight: 700;

  @include mq("xl", "max") {
    font-size: 14px;
  }

  @include mq("md", "max") {
    font-size: 12px;
  }
}
.pa-kf-subheading {
  font-size: 16px;

  @include mq("xl", "max") {
    font-size: 14px;
  }

  @include mq("md", "max") {
    font-size: 12px;
  }
}
.content-wrapper {
  border: 1px solid black;
}
.form-details-pa {
  font-weight: 600;
  color: #5a5a5a;
}
.heroBanner-pa {
  background: url(../../../public/assets/img/personalAccident-newBanner.png) no-repeat center bottom #808285;
  background-size: cover;
  min-height: 730px;

  @include mq("lg", "max") {
    background: $gray-200;
    min-height: 100vh;
  }
}
.pac-title {
  font-size: 35px;
}
.heroBanner-personalAccident {
  padding-top: 100px;
}
// Personal Accident css end

// Auto Css start
.rupeesRed{
  font-size: 12px;
  color: #e91431;
}
.custom-radio-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.custom-radio-btn input {
  display: none;
}

.custom-radio-btn label {
  padding: 10px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  background-color: white;
  display: inline-block;
  min-width: 100px;
  text-align: center;
}

.custom-radio-btn.active label,
.custom-radio-btn label:hover {
  border-color: #e91431;
  background-color: rgba(233, 20, 49, 0.03);
  color: #e91431;
}
// Auto Css end
