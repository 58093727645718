.react-datepicker-popper {
  z-index: 2;
}
.react-datepicker__header--custom {
  background-color: white;
  z-index: 10;
  .react-select__menu-list::-webkit-scrollbar,
  .react-select-month__menu-list::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  .react-select__menu-list::-webkit-scrollbar-track,
  .react-select-month__menu-list::-webkit-scrollbar-track {
    background: transparent;
  }
  .react-select__menu-list::-webkit-scrollbar-thumb,
  .react-select-month__menu-list::-webkit-scrollbar-thumb {
    background: $gray-500;
    border-radius: 2px;
    opacity: 0;
  }
}

.react-select-container {
  height: 30px;
  .react-select__control {
    min-height: auto;
    min-width: 60px;
    margin: auto;
    padding: auto;
  }
  .react-select-month__control {
    min-width: 120px;
    min-height: auto;
    margin: auto;
    padding: auto;
  }
}
